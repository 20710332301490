// tslint:disable
/**
 * Dashboard API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ClusterinfoClusterStatistics
 */
export interface ClusterinfoClusterStatistics {
    /**
     * 
     * @type {number}
     * @memberof ClusterinfoClusterStatistics
     */
    probe_failure_hosts?: number;
    /**
     * 
     * @type {{ [key: string]: ClusterinfoClusterStatisticsPartial; }}
     * @memberof ClusterinfoClusterStatistics
     */
    stats_by_instance_kind?: { [key: string]: ClusterinfoClusterStatisticsPartial; };
    /**
     * 
     * @type {ClusterinfoClusterStatisticsPartial}
     * @memberof ClusterinfoClusterStatistics
     */
    total_stats?: ClusterinfoClusterStatisticsPartial;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClusterinfoClusterStatistics
     */
    versions?: Array<string>;
}
/**
 * 
 * @export
 * @interface ClusterinfoClusterStatisticsPartial
 */
export interface ClusterinfoClusterStatisticsPartial {
    /**
     * 
     * @type {number}
     * @memberof ClusterinfoClusterStatisticsPartial
     */
    number_of_hosts?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterinfoClusterStatisticsPartial
     */
    number_of_instances?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterinfoClusterStatisticsPartial
     */
    total_logical_cores?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterinfoClusterStatisticsPartial
     */
    total_memory_capacity_bytes?: number;
    /**
     * 
     * @type {number}
     * @memberof ClusterinfoClusterStatisticsPartial
     */
    total_physical_cores?: number;
}
/**
 * 
 * @export
 * @interface ClusterinfoGetHostsInfoResponse
 */
export interface ClusterinfoGetHostsInfoResponse {
    /**
     * 
     * @type {Array<HostinfoInfo>}
     * @memberof ClusterinfoGetHostsInfoResponse
     */
    hosts?: Array<HostinfoInfo>;
    /**
     * 
     * @type {UtilsAPIError}
     * @memberof ClusterinfoGetHostsInfoResponse
     */
    warning?: UtilsAPIError;
}
/**
 * 
 * @export
 * @interface ClusterinfoStoreTopologyResponse
 */
export interface ClusterinfoStoreTopologyResponse {
    /**
     * 
     * @type {Array<TopologyStoreInfo>}
     * @memberof ClusterinfoStoreTopologyResponse
     */
    tiflash?: Array<TopologyStoreInfo>;
    /**
     * 
     * @type {Array<TopologyStoreInfo>}
     * @memberof ClusterinfoStoreTopologyResponse
     */
    tikv?: Array<TopologyStoreInfo>;
}
/**
 * 
 * @export
 * @interface CodeShareRequest
 */
export interface CodeShareRequest {
    /**
     * 
     * @type {number}
     * @memberof CodeShareRequest
     */
    expire_in_sec?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CodeShareRequest
     */
    revoke_write_priv?: boolean;
}
/**
 * 
 * @export
 * @interface CodeShareResponse
 */
export interface CodeShareResponse {
    /**
     * 
     * @type {string}
     * @memberof CodeShareResponse
     */
    code?: string;
}
/**
 * 
 * @export
 * @interface ConfigKeyVisualConfig
 */
export interface ConfigKeyVisualConfig {
    /**
     * 
     * @type {boolean}
     * @memberof ConfigKeyVisualConfig
     */
    auto_collection_disabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ConfigKeyVisualConfig
     */
    policy?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigKeyVisualConfig
     */
    policy_kv_separator?: string;
}
/**
 * 
 * @export
 * @interface ConfigProfilingConfig
 */
export interface ConfigProfilingConfig {
    /**
     * 
     * @type {number}
     * @memberof ConfigProfilingConfig
     */
    auto_collection_duration_secs?: number;
    /**
     * 
     * @type {number}
     * @memberof ConfigProfilingConfig
     */
    auto_collection_interval_secs?: number;
    /**
     * 
     * @type {Array<ModelRequestTargetNode>}
     * @memberof ConfigProfilingConfig
     */
    auto_collection_targets?: Array<ModelRequestTargetNode>;
}
/**
 * 
 * @export
 * @interface ConfigSSOCoreConfig
 */
export interface ConfigSSOCoreConfig {
    /**
     * 
     * @type {string}
     * @memberof ConfigSSOCoreConfig
     */
    client_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigSSOCoreConfig
     */
    discovery_url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigSSOCoreConfig
     */
    enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigSSOCoreConfig
     */
    is_read_only?: boolean;
}
/**
 * 
 * @export
 * @interface ConfigurationAllConfigItems
 */
export interface ConfigurationAllConfigItems {
    /**
     * 
     * @type {Array<UtilsAPIError>}
     * @memberof ConfigurationAllConfigItems
     */
    errors?: Array<UtilsAPIError>;
    /**
     * 
     * @type {{ [key: string]: Array<ConfigurationItem>; }}
     * @memberof ConfigurationAllConfigItems
     */
    items?: { [key: string]: Array<ConfigurationItem>; };
}
/**
 * 
 * @export
 * @interface ConfigurationEditRequest
 */
export interface ConfigurationEditRequest {
    /**
     * 
     * @type {string}
     * @memberof ConfigurationEditRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigurationEditRequest
     */
    kind?: string;
    /**
     * 
     * @type {object}
     * @memberof ConfigurationEditRequest
     */
    new_value?: object;
}
/**
 * 
 * @export
 * @interface ConfigurationEditResponse
 */
export interface ConfigurationEditResponse {
    /**
     * 
     * @type {Array<UtilsAPIError>}
     * @memberof ConfigurationEditResponse
     */
    warnings?: Array<UtilsAPIError>;
}
/**
 * 
 * @export
 * @interface ConfigurationItem
 */
export interface ConfigurationItem {
    /**
     * 
     * @type {string}
     * @memberof ConfigurationItem
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ConfigurationItem
     */
    is_editable?: boolean;
    /**
     * TODO: Support per-instance config
     * @type {boolean}
     * @memberof ConfigurationItem
     */
    is_multi_value?: boolean;
    /**
     * When multi value present, this contains one of the value
     * @type {object}
     * @memberof ConfigurationItem
     */
    value?: object;
}
/**
 * 
 * @export
 * @interface DecoratorLabelKey
 */
export interface DecoratorLabelKey {
    /**
     * 
     * @type {string}
     * @memberof DecoratorLabelKey
     */
    key: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DecoratorLabelKey
     */
    labels: Array<string>;
}
/**
 * 
 * @export
 * @interface DiagnoseGenerateMetricsRelationRequest
 */
export interface DiagnoseGenerateMetricsRelationRequest {
    /**
     * 
     * @type {number}
     * @memberof DiagnoseGenerateMetricsRelationRequest
     */
    end_time?: number;
    /**
     * 
     * @type {number}
     * @memberof DiagnoseGenerateMetricsRelationRequest
     */
    start_time?: number;
    /**
     * 
     * @type {string}
     * @memberof DiagnoseGenerateMetricsRelationRequest
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface DiagnoseGenerateReportRequest
 */
export interface DiagnoseGenerateReportRequest {
    /**
     * 
     * @type {number}
     * @memberof DiagnoseGenerateReportRequest
     */
    compare_end_time?: number;
    /**
     * 
     * @type {number}
     * @memberof DiagnoseGenerateReportRequest
     */
    compare_start_time?: number;
    /**
     * 
     * @type {number}
     * @memberof DiagnoseGenerateReportRequest
     */
    end_time?: number;
    /**
     * 
     * @type {number}
     * @memberof DiagnoseGenerateReportRequest
     */
    start_time?: number;
}
/**
 * 
 * @export
 * @interface DiagnoseReport
 */
export interface DiagnoseReport {
    /**
     * 
     * @type {string}
     * @memberof DiagnoseReport
     */
    compare_end_time?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnoseReport
     */
    compare_start_time?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnoseReport
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnoseReport
     */
    created_at?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnoseReport
     */
    end_time?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnoseReport
     */
    id?: string;
    /**
     * 0~100
     * @type {number}
     * @memberof DiagnoseReport
     */
    progress?: number;
    /**
     * 
     * @type {string}
     * @memberof DiagnoseReport
     */
    start_time?: string;
}
/**
 * 
 * @export
 * @interface EndpointAPIModel
 */
export interface EndpointAPIModel {
    /**
     * 
     * @type {string}
     * @memberof EndpointAPIModel
     */
    component?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointAPIModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointAPIModel
     */
    method?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointAPIModel
     */
    path?: string;
    /**
     * e.g. /stats/dump/{db}/{table} -> db, table
     * @type {Array<EndpointAPIParam>}
     * @memberof EndpointAPIModel
     */
    path_params?: Array<EndpointAPIParam>;
    /**
     * e.g. /debug/pprof?seconds=1 -> seconds
     * @type {Array<EndpointAPIParam>}
     * @memberof EndpointAPIModel
     */
    query_params?: Array<EndpointAPIParam>;
}
/**
 * 
 * @export
 * @interface EndpointAPIParam
 */
export interface EndpointAPIParam {
    /**
     * represents what param is
     * @type {object}
     * @memberof EndpointAPIParam
     */
    model?: object;
    /**
     * 
     * @type {string}
     * @memberof EndpointAPIParam
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EndpointAPIParam
     */
    required?: boolean;
}
/**
 * 
 * @export
 * @interface EndpointRequestPayload
 */
export interface EndpointRequestPayload {
    /**
     * 
     * @type {string}
     * @memberof EndpointRequestPayload
     */
    host?: string;
    /**
     * 
     * @type {string}
     * @memberof EndpointRequestPayload
     */
    id?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof EndpointRequestPayload
     */
    params?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof EndpointRequestPayload
     */
    port?: number;
}
/**
 * 
 * @export
 * @interface HostinfoCPUInfo
 */
export interface HostinfoCPUInfo {
    /**
     * 
     * @type {string}
     * @memberof HostinfoCPUInfo
     */
    arch?: string;
    /**
     * 
     * @type {number}
     * @memberof HostinfoCPUInfo
     */
    logical_cores?: number;
    /**
     * 
     * @type {number}
     * @memberof HostinfoCPUInfo
     */
    physical_cores?: number;
}
/**
 * 
 * @export
 * @interface HostinfoCPUUsageInfo
 */
export interface HostinfoCPUUsageInfo {
    /**
     * 
     * @type {number}
     * @memberof HostinfoCPUUsageInfo
     */
    idle?: number;
    /**
     * 
     * @type {number}
     * @memberof HostinfoCPUUsageInfo
     */
    system?: number;
}
/**
 * 
 * @export
 * @interface HostinfoInfo
 */
export interface HostinfoInfo {
    /**
     * 
     * @type {HostinfoCPUInfo}
     * @memberof HostinfoInfo
     */
    cpu_info?: HostinfoCPUInfo;
    /**
     * 
     * @type {HostinfoCPUUsageInfo}
     * @memberof HostinfoInfo
     */
    cpu_usage?: HostinfoCPUUsageInfo;
    /**
     * 
     * @type {string}
     * @memberof HostinfoInfo
     */
    host?: string;
    /**
     * Instances in the current host. The key is instance address
     * @type {{ [key: string]: HostinfoInstanceInfo; }}
     * @memberof HostinfoInfo
     */
    instances?: { [key: string]: HostinfoInstanceInfo; };
    /**
     * 
     * @type {HostinfoMemoryUsageInfo}
     * @memberof HostinfoInfo
     */
    memory_usage?: HostinfoMemoryUsageInfo;
    /**
     * Containing unused partitions. The key is path in lower case. Note: deviceName is not used as the key, since TiDB and TiKV may return different deviceName for the same device.
     * @type {{ [key: string]: HostinfoPartitionInfo; }}
     * @memberof HostinfoInfo
     */
    partitions?: { [key: string]: HostinfoPartitionInfo; };
}
/**
 * 
 * @export
 * @interface HostinfoInstanceInfo
 */
export interface HostinfoInstanceInfo {
    /**
     * 
     * @type {string}
     * @memberof HostinfoInstanceInfo
     */
    partition_path_lower?: string;
    /**
     * 
     * @type {string}
     * @memberof HostinfoInstanceInfo
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface HostinfoMemoryUsageInfo
 */
export interface HostinfoMemoryUsageInfo {
    /**
     * 
     * @type {number}
     * @memberof HostinfoMemoryUsageInfo
     */
    total?: number;
    /**
     * 
     * @type {number}
     * @memberof HostinfoMemoryUsageInfo
     */
    used?: number;
}
/**
 * 
 * @export
 * @interface HostinfoPartitionInfo
 */
export interface HostinfoPartitionInfo {
    /**
     * 
     * @type {number}
     * @memberof HostinfoPartitionInfo
     */
    free?: number;
    /**
     * 
     * @type {string}
     * @memberof HostinfoPartitionInfo
     */
    fstype?: string;
    /**
     * 
     * @type {string}
     * @memberof HostinfoPartitionInfo
     */
    path?: string;
    /**
     * 
     * @type {number}
     * @memberof HostinfoPartitionInfo
     */
    total?: number;
}
/**
 * 
 * @export
 * @interface InfoInfoResponse
 */
export interface InfoInfoResponse {
    /**
     * 
     * @type {boolean}
     * @memberof InfoInfoResponse
     */
    enable_experimental?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InfoInfoResponse
     */
    enable_telemetry?: boolean;
    /**
     * 
     * @type {VersionInfo}
     * @memberof InfoInfoResponse
     */
    version?: VersionInfo;
}
/**
 * 
 * @export
 * @interface InfoTableSchema
 */
export interface InfoTableSchema {
    /**
     * 
     * @type {string}
     * @memberof InfoTableSchema
     */
    table_id?: string;
    /**
     * 
     * @type {string}
     * @memberof InfoTableSchema
     */
    table_name?: string;
}
/**
 * 
 * @export
 * @interface InfoWhoAmIResponse
 */
export interface InfoWhoAmIResponse {
    /**
     * 
     * @type {string}
     * @memberof InfoWhoAmIResponse
     */
    display_name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InfoWhoAmIResponse
     */
    is_shareable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InfoWhoAmIResponse
     */
    is_writeable?: boolean;
}
/**
 * 
 * @export
 * @interface LogsearchCreateTaskGroupRequest
 */
export interface LogsearchCreateTaskGroupRequest {
    /**
     * 
     * @type {LogsearchSearchLogRequest}
     * @memberof LogsearchCreateTaskGroupRequest
     */
    request: LogsearchSearchLogRequest;
    /**
     * 
     * @type {Array<ModelRequestTargetNode>}
     * @memberof LogsearchCreateTaskGroupRequest
     */
    targets: Array<ModelRequestTargetNode>;
}
/**
 * 
 * @export
 * @interface LogsearchPreviewModel
 */
export interface LogsearchPreviewModel {
    /**
     * 
     * @type {number}
     * @memberof LogsearchPreviewModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LogsearchPreviewModel
     */
    level?: string;
    /**
     * 
     * @type {string}
     * @memberof LogsearchPreviewModel
     */
    message?: string;
    /**
     * 
     * @type {number}
     * @memberof LogsearchPreviewModel
     */
    task_group_id?: number;
    /**
     * 
     * @type {number}
     * @memberof LogsearchPreviewModel
     */
    task_id?: number;
    /**
     * 
     * @type {number}
     * @memberof LogsearchPreviewModel
     */
    time?: number;
}
/**
 * 
 * @export
 * @interface LogsearchSearchLogRequest
 */
export interface LogsearchSearchLogRequest {
    /**
     * 
     * @type {number}
     * @memberof LogsearchSearchLogRequest
     */
    end_time?: number;
    /**
     * 
     * @type {number}
     * @memberof LogsearchSearchLogRequest
     */
    min_level?: number;
    /**
     * We use a string array to represent multiple CNF pattern sceniaor like: SELECT * FROM t WHERE c LIKE \'%s%\' and c REGEXP \'.*a.*\' because Golang and Rust don\'t support perl-like (?=re1)(?=re2)
     * @type {Array<string>}
     * @memberof LogsearchSearchLogRequest
     */
    patterns?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LogsearchSearchLogRequest
     */
    start_time?: number;
}
/**
 * 
 * @export
 * @interface LogsearchTaskGroupModel
 */
export interface LogsearchTaskGroupModel {
    /**
     * 
     * @type {number}
     * @memberof LogsearchTaskGroupModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LogsearchTaskGroupModel
     */
    log_store_dir?: string;
    /**
     * 
     * @type {LogsearchSearchLogRequest}
     * @memberof LogsearchTaskGroupModel
     */
    search_request?: LogsearchSearchLogRequest;
    /**
     * 
     * @type {number}
     * @memberof LogsearchTaskGroupModel
     */
    state?: number;
    /**
     * 
     * @type {ModelRequestTargetStatistics}
     * @memberof LogsearchTaskGroupModel
     */
    target_stats?: ModelRequestTargetStatistics;
}
/**
 * 
 * @export
 * @interface LogsearchTaskGroupResponse
 */
export interface LogsearchTaskGroupResponse {
    /**
     * 
     * @type {LogsearchTaskGroupModel}
     * @memberof LogsearchTaskGroupResponse
     */
    task_group?: LogsearchTaskGroupModel;
    /**
     * 
     * @type {Array<LogsearchTaskModel>}
     * @memberof LogsearchTaskGroupResponse
     */
    tasks?: Array<LogsearchTaskModel>;
}
/**
 * 
 * @export
 * @interface LogsearchTaskModel
 */
export interface LogsearchTaskModel {
    /**
     * 
     * @type {string}
     * @memberof LogsearchTaskModel
     */
    error?: string;
    /**
     * 
     * @type {number}
     * @memberof LogsearchTaskModel
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LogsearchTaskModel
     */
    log_store_path?: string;
    /**
     * 
     * @type {number}
     * @memberof LogsearchTaskModel
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof LogsearchTaskModel
     */
    slow_log_store_path?: string;
    /**
     * 
     * @type {number}
     * @memberof LogsearchTaskModel
     */
    state?: number;
    /**
     * 
     * @type {ModelRequestTargetNode}
     * @memberof LogsearchTaskModel
     */
    target?: ModelRequestTargetNode;
    /**
     * 
     * @type {number}
     * @memberof LogsearchTaskModel
     */
    task_group_id?: number;
}
/**
 * 
 * @export
 * @interface MatrixMatrix
 */
export interface MatrixMatrix {
    /**
     * 
     * @type {{ [key: string]: Array<Array<number>>; }}
     * @memberof MatrixMatrix
     */
    data: { [key: string]: Array<Array<number>>; };
    /**
     * 
     * @type {Array<DecoratorLabelKey>}
     * @memberof MatrixMatrix
     */
    keyAxis: Array<DecoratorLabelKey>;
    /**
     * 
     * @type {Array<number>}
     * @memberof MatrixMatrix
     */
    timeAxis: Array<number>;
}
/**
 * 
 * @export
 * @interface MetricsGetPromAddressConfigResponse
 */
export interface MetricsGetPromAddressConfigResponse {
    /**
     * 
     * @type {string}
     * @memberof MetricsGetPromAddressConfigResponse
     */
    customized_addr?: string;
    /**
     * 
     * @type {string}
     * @memberof MetricsGetPromAddressConfigResponse
     */
    deployed_addr?: string;
}
/**
 * 
 * @export
 * @interface MetricsPutCustomPromAddressRequest
 */
export interface MetricsPutCustomPromAddressRequest {
    /**
     * 
     * @type {string}
     * @memberof MetricsPutCustomPromAddressRequest
     */
    address?: string;
}
/**
 * 
 * @export
 * @interface MetricsPutCustomPromAddressResponse
 */
export interface MetricsPutCustomPromAddressResponse {
    /**
     * 
     * @type {string}
     * @memberof MetricsPutCustomPromAddressResponse
     */
    normalized_address?: string;
}
/**
 * 
 * @export
 * @interface MetricsQueryRequest
 */
export interface MetricsQueryRequest {
    /**
     * 
     * @type {number}
     * @memberof MetricsQueryRequest
     */
    end_time_sec?: number;
    /**
     * 
     * @type {string}
     * @memberof MetricsQueryRequest
     */
    query?: string;
    /**
     * 
     * @type {number}
     * @memberof MetricsQueryRequest
     */
    start_time_sec?: number;
    /**
     * 
     * @type {number}
     * @memberof MetricsQueryRequest
     */
    step_sec?: number;
}
/**
 * 
 * @export
 * @interface MetricsQueryResponse
 */
export interface MetricsQueryResponse {
    /**
     * 
     * @type {object}
     * @memberof MetricsQueryResponse
     */
    data?: object;
    /**
     * 
     * @type {string}
     * @memberof MetricsQueryResponse
     */
    status?: string;
}
/**
 * 
 * @export
 * @interface ModelRequestTargetNode
 */
export interface ModelRequestTargetNode {
    /**
     * 
     * @type {string}
     * @memberof ModelRequestTargetNode
     */
    display_name?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelRequestTargetNode
     */
    ip?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelRequestTargetNode
     */
    kind?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelRequestTargetNode
     */
    port?: number;
}
/**
 * 
 * @export
 * @interface ModelRequestTargetStatistics
 */
export interface ModelRequestTargetStatistics {
    /**
     * 
     * @type {number}
     * @memberof ModelRequestTargetStatistics
     */
    num_pd_nodes?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelRequestTargetStatistics
     */
    num_tidb_nodes?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelRequestTargetStatistics
     */
    num_tiflash_nodes?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelRequestTargetStatistics
     */
    num_tikv_nodes?: number;
}
/**
 * 
 * @export
 * @interface ProfilingComponent
 */
export interface ProfilingComponent {
    /**
     * 
     * @type {string}
     * @memberof ProfilingComponent
     */
    ip?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfilingComponent
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfilingComponent
     */
    port?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfilingComponent
     */
    status_port?: number;
}
/**
 * 
 * @export
 * @interface ProfilingComponentNum
 */
export interface ProfilingComponentNum {
    /**
     * 
     * @type {number}
     * @memberof ProfilingComponentNum
     */
    pd?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfilingComponentNum
     */
    tidb?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfilingComponentNum
     */
    tiflash?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfilingComponentNum
     */
    tikv?: number;
}
/**
 * 
 * @export
 * @interface ProfilingContinuousProfilingConfig
 */
export interface ProfilingContinuousProfilingConfig {
    /**
     * 
     * @type {number}
     * @memberof ProfilingContinuousProfilingConfig
     */
    data_retention_seconds?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProfilingContinuousProfilingConfig
     */
    enable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProfilingContinuousProfilingConfig
     */
    interval_seconds?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfilingContinuousProfilingConfig
     */
    profile_seconds?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfilingContinuousProfilingConfig
     */
    timeout_seconds?: number;
}
/**
 * 
 * @export
 * @interface ProfilingEstimateSizeRes
 */
export interface ProfilingEstimateSizeRes {
    /**
     * 
     * @type {number}
     * @memberof ProfilingEstimateSizeRes
     */
    instance_count?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfilingEstimateSizeRes
     */
    profile_size?: number;
}
/**
 * 
 * @export
 * @interface ProfilingGetGroupProfileReq
 */
export interface ProfilingGetGroupProfileReq {
    /**
     * 
     * @type {number}
     * @memberof ProfilingGetGroupProfileReq
     */
    begin_time?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfilingGetGroupProfileReq
     */
    end_time?: number;
}
/**
 * 
 * @export
 * @interface ProfilingGroupDetailResponse
 */
export interface ProfilingGroupDetailResponse {
    /**
     * 
     * @type {number}
     * @memberof ProfilingGroupDetailResponse
     */
    server_time?: number;
    /**
     * 
     * @type {ProfilingTaskGroupModel}
     * @memberof ProfilingGroupDetailResponse
     */
    task_group_status?: ProfilingTaskGroupModel;
    /**
     * 
     * @type {Array<ProfilingTaskModel>}
     * @memberof ProfilingGroupDetailResponse
     */
    tasks_status?: Array<ProfilingTaskModel>;
}
/**
 * 
 * @export
 * @interface ProfilingGroupProfileDetail
 */
export interface ProfilingGroupProfileDetail {
    /**
     * 
     * @type {number}
     * @memberof ProfilingGroupProfileDetail
     */
    profile_duration_secs?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfilingGroupProfileDetail
     */
    state?: string;
    /**
     * 
     * @type {Array<ProfilingProfileDetail>}
     * @memberof ProfilingGroupProfileDetail
     */
    target_profiles?: Array<ProfilingProfileDetail>;
    /**
     * 
     * @type {number}
     * @memberof ProfilingGroupProfileDetail
     */
    ts?: number;
}
/**
 * 
 * @export
 * @interface ProfilingGroupProfiles
 */
export interface ProfilingGroupProfiles {
    /**
     * 
     * @type {ProfilingComponentNum}
     * @memberof ProfilingGroupProfiles
     */
    component_num?: ProfilingComponentNum;
    /**
     * 
     * @type {number}
     * @memberof ProfilingGroupProfiles
     */
    profile_duration_secs?: number;
    /**
     * 
     * @type {string}
     * @memberof ProfilingGroupProfiles
     */
    state?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfilingGroupProfiles
     */
    ts?: number;
}
/**
 * 
 * @export
 * @interface ProfilingNgMonitoringConfig
 */
export interface ProfilingNgMonitoringConfig {
    /**
     * 
     * @type {ProfilingContinuousProfilingConfig}
     * @memberof ProfilingNgMonitoringConfig
     */
    continuous_profiling?: ProfilingContinuousProfilingConfig;
}
/**
 * 
 * @export
 * @interface ProfilingProfileDetail
 */
export interface ProfilingProfileDetail {
    /**
     * 
     * @type {string}
     * @memberof ProfilingProfileDetail
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfilingProfileDetail
     */
    profile_type?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfilingProfileDetail
     */
    state?: string;
    /**
     * 
     * @type {ProfilingTarget}
     * @memberof ProfilingProfileDetail
     */
    target?: ProfilingTarget;
}
/**
 * 
 * @export
 * @interface ProfilingStartRequest
 */
export interface ProfilingStartRequest {
    /**
     * 
     * @type {number}
     * @memberof ProfilingStartRequest
     */
    duration_secs?: number;
    /**
     * 
     * @type {Array<ModelRequestTargetNode>}
     * @memberof ProfilingStartRequest
     */
    targets?: Array<ModelRequestTargetNode>;
}
/**
 * 
 * @export
 * @interface ProfilingTarget
 */
export interface ProfilingTarget {
    /**
     * 
     * @type {string}
     * @memberof ProfilingTarget
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfilingTarget
     */
    component?: string;
}
/**
 * 
 * @export
 * @interface ProfilingTaskGroupModel
 */
export interface ProfilingTaskGroupModel {
    /**
     * 
     * @type {number}
     * @memberof ProfilingTaskGroupModel
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfilingTaskGroupModel
     */
    profile_duration_secs?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfilingTaskGroupModel
     */
    started_at?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfilingTaskGroupModel
     */
    state?: number;
    /**
     * 
     * @type {ModelRequestTargetStatistics}
     * @memberof ProfilingTaskGroupModel
     */
    target_stats?: ModelRequestTargetStatistics;
}
/**
 * 
 * @export
 * @interface ProfilingTaskModel
 */
export interface ProfilingTaskModel {
    /**
     * 
     * @type {string}
     * @memberof ProfilingTaskModel
     */
    error?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfilingTaskModel
     */
    file_path?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfilingTaskModel
     */
    id?: number;
    /**
     * The start running time, reset when retry. Used to estimate approximate profiling progress.
     * @type {number}
     * @memberof ProfilingTaskModel
     */
    started_at?: number;
    /**
     * 
     * @type {number}
     * @memberof ProfilingTaskModel
     */
    state?: number;
    /**
     * 
     * @type {ModelRequestTargetNode}
     * @memberof ProfilingTaskModel
     */
    target?: ModelRequestTargetNode;
    /**
     * 
     * @type {number}
     * @memberof ProfilingTaskModel
     */
    task_group_id?: number;
}
/**
 * 
 * @export
 * @interface ProfilingViewSingleProfileReq
 */
export interface ProfilingViewSingleProfileReq {
    /**
     * 
     * @type {string}
     * @memberof ProfilingViewSingleProfileReq
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfilingViewSingleProfileReq
     */
    component?: string;
    /**
     * 
     * @type {string}
     * @memberof ProfilingViewSingleProfileReq
     */
    profile_type?: string;
    /**
     * 
     * @type {number}
     * @memberof ProfilingViewSingleProfileReq
     */
    ts?: number;
}
/**
 * 
 * @export
 * @interface QueryeditorRunRequest
 */
export interface QueryeditorRunRequest {
    /**
     * 
     * @type {number}
     * @memberof QueryeditorRunRequest
     */
    max_rows?: number;
    /**
     * 
     * @type {string}
     * @memberof QueryeditorRunRequest
     */
    statements?: string;
}
/**
 * 
 * @export
 * @interface QueryeditorRunResponse
 */
export interface QueryeditorRunResponse {
    /**
     * 
     * @type {number}
     * @memberof QueryeditorRunResponse
     */
    actual_rows?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof QueryeditorRunResponse
     */
    column_names?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof QueryeditorRunResponse
     */
    error_msg?: string;
    /**
     * 
     * @type {number}
     * @memberof QueryeditorRunResponse
     */
    execution_ms?: number;
    /**
     * 
     * @type {Array<Array<object>>}
     * @memberof QueryeditorRunResponse
     */
    rows?: Array<Array<object>>;
}
/**
 * 
 * @export
 * @interface SlowqueryGetDetailRequest
 */
export interface SlowqueryGetDetailRequest {
    /**
     * TODO: Switch back to uint64 when modern browser as well as Swagger handles BigInt well.
     * @type {string}
     * @memberof SlowqueryGetDetailRequest
     */
    connect_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SlowqueryGetDetailRequest
     */
    digest?: string;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryGetDetailRequest
     */
    timestamp?: number;
}
/**
 * 
 * @export
 * @interface SlowqueryGetListRequest
 */
export interface SlowqueryGetListRequest {
    /**
     * 
     * @type {number}
     * @memberof SlowqueryGetListRequest
     */
    begin_time?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SlowqueryGetListRequest
     */
    db?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof SlowqueryGetListRequest
     */
    desc?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SlowqueryGetListRequest
     */
    digest?: string;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryGetListRequest
     */
    end_time?: number;
    /**
     * example: \"Query,Digest\"
     * @type {string}
     * @memberof SlowqueryGetListRequest
     */
    fields?: string;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryGetListRequest
     */
    limit?: number;
    /**
     * 
     * @type {string}
     * @memberof SlowqueryGetListRequest
     */
    orderBy?: string;
    /**
     * for showing slow queries in the statement detail page
     * @type {Array<string>}
     * @memberof SlowqueryGetListRequest
     */
    plans?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SlowqueryGetListRequest
     */
    text?: string;
}
/**
 * 
 * @export
 * @interface SlowqueryModel
 */
export interface SlowqueryModel {
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    backoff_time?: number;
    /**
     * 
     * @type {string}
     * @memberof SlowqueryModel
     */
    backoff_types?: string;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    commit_backoff_time?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    commit_time?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    compile_time?: number;
    /**
     * TODO: Switch back to uint64 when modern browser as well as Swagger handles BigInt well.
     * @type {string}
     * @memberof SlowqueryModel
     */
    connection_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SlowqueryModel
     */
    cop_proc_addr?: string;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    cop_proc_avg?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    cop_proc_max?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    cop_proc_p90?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    cop_time?: number;
    /**
     * 
     * @type {string}
     * @memberof SlowqueryModel
     */
    cop_wait_addr?: string;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    cop_wait_avg?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    cop_wait_max?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    cop_wait_p90?: number;
    /**
     * 
     * @type {string}
     * @memberof SlowqueryModel
     */
    db?: string;
    /**
     * 
     * @type {string}
     * @memberof SlowqueryModel
     */
    digest?: string;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    disk_max?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    exec_retry_time?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    get_commit_ts_time?: number;
    /**
     * 
     * @type {string}
     * @memberof SlowqueryModel
     */
    host?: string;
    /**
     * 
     * @type {string}
     * @memberof SlowqueryModel
     */
    index_names?: string;
    /**
     * 
     * @type {string}
     * @memberof SlowqueryModel
     */
    instance?: string;
    /**
     * Basic
     * @type {number}
     * @memberof SlowqueryModel
     */
    is_internal?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    local_latch_wait_time?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    lock_keys_time?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    memory_max?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    optimize_time?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    parse_time?: number;
    /**
     * 
     * @type {string}
     * @memberof SlowqueryModel
     */
    plan?: string;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    preproc_subqueries_time?: number;
    /**
     * Detail
     * @type {string}
     * @memberof SlowqueryModel
     */
    prev_stmt?: string;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    prewrite_region?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    prewrite_time?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    process_keys?: number;
    /**
     * Time
     * @type {number}
     * @memberof SlowqueryModel
     */
    process_time?: number;
    /**
     * 
     * @type {string}
     * @memberof SlowqueryModel
     */
    query?: string;
    /**
     * latency
     * @type {number}
     * @memberof SlowqueryModel
     */
    query_time?: number;
    /**
     * Coprocessor
     * @type {number}
     * @memberof SlowqueryModel
     */
    request_count?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    resolve_lock_time?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    rewrite_time?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    rocksdb_block_cache_hit_count?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    rocksdb_block_read_byte?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    rocksdb_block_read_count?: number;
    /**
     * RocksDB
     * @type {number}
     * @memberof SlowqueryModel
     */
    rocksdb_delete_skipped_count?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    rocksdb_key_skipped_count?: number;
    /**
     * 
     * @type {string}
     * @memberof SlowqueryModel
     */
    stats?: string;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    success?: number;
    /**
     * finish time
     * @type {number}
     * @memberof SlowqueryModel
     */
    timestamp?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    total_keys?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    txn_retry?: number;
    /**
     * TODO: Switch back to uint64 when modern browser as well as Swagger handles BigInt well.
     * @type {string}
     * @memberof SlowqueryModel
     */
    txn_start_ts?: string;
    /**
     * Connection
     * @type {string}
     * @memberof SlowqueryModel
     */
    user?: string;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    wait_prewrite_binlog_time?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    wait_time?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    wait_ts?: number;
    /**
     * Transaction
     * @type {number}
     * @memberof SlowqueryModel
     */
    write_keys?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    write_size?: number;
    /**
     * 
     * @type {number}
     * @memberof SlowqueryModel
     */
    write_sql_response_total?: number;
}
/**
 * 
 * @export
 * @interface SsoCreateImpersonationRequest
 */
export interface SsoCreateImpersonationRequest {
    /**
     * 
     * @type {string}
     * @memberof SsoCreateImpersonationRequest
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof SsoCreateImpersonationRequest
     */
    sql_user?: string;
}
/**
 * 
 * @export
 * @interface SsoGetAuthURLRequest
 */
export interface SsoGetAuthURLRequest {
    /**
     * 
     * @type {string}
     * @memberof SsoGetAuthURLRequest
     */
    code_verifier?: string;
    /**
     * 
     * @type {string}
     * @memberof SsoGetAuthURLRequest
     */
    redirect_url?: string;
    /**
     * 
     * @type {string}
     * @memberof SsoGetAuthURLRequest
     */
    state?: string;
}
/**
 * 
 * @export
 * @interface SsoSSOImpersonationModel
 */
export interface SsoSSOImpersonationModel {
    /**
     * 
     * @type {string}
     * @memberof SsoSSOImpersonationModel
     */
    last_impersonate_status?: string;
    /**
     * 
     * @type {string}
     * @memberof SsoSSOImpersonationModel
     */
    sql_user?: string;
}
/**
 * 
 * @export
 * @interface SsoSetConfigRequest
 */
export interface SsoSetConfigRequest {
    /**
     * 
     * @type {ConfigSSOCoreConfig}
     * @memberof SsoSetConfigRequest
     */
    config?: ConfigSSOCoreConfig;
}
/**
 * 
 * @export
 * @interface StatementEditableConfig
 */
export interface StatementEditableConfig {
    /**
     * 
     * @type {boolean}
     * @memberof StatementEditableConfig
     */
    enable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof StatementEditableConfig
     */
    history_size?: number;
    /**
     * 
     * @type {boolean}
     * @memberof StatementEditableConfig
     */
    internal_query?: boolean;
    /**
     * 
     * @type {number}
     * @memberof StatementEditableConfig
     */
    max_size?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementEditableConfig
     */
    refresh_interval?: number;
}
/**
 * 
 * @export
 * @interface StatementGetPlanDetailRequest
 */
export interface StatementGetPlanDetailRequest {
    /**
     * 
     * @type {number}
     * @memberof StatementGetPlanDetailRequest
     */
    begin_time?: number;
    /**
     * 
     * @type {string}
     * @memberof StatementGetPlanDetailRequest
     */
    digest?: string;
    /**
     * 
     * @type {number}
     * @memberof StatementGetPlanDetailRequest
     */
    end_time?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatementGetPlanDetailRequest
     */
    plans?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StatementGetPlanDetailRequest
     */
    schema_name?: string;
}
/**
 * 
 * @export
 * @interface StatementGetPlansRequest
 */
export interface StatementGetPlansRequest {
    /**
     * 
     * @type {number}
     * @memberof StatementGetPlansRequest
     */
    begin_time?: number;
    /**
     * 
     * @type {string}
     * @memberof StatementGetPlansRequest
     */
    digest?: string;
    /**
     * 
     * @type {number}
     * @memberof StatementGetPlansRequest
     */
    end_time?: number;
    /**
     * 
     * @type {string}
     * @memberof StatementGetPlansRequest
     */
    schema_name?: string;
}
/**
 * 
 * @export
 * @interface StatementGetStatementsRequest
 */
export interface StatementGetStatementsRequest {
    /**
     * 
     * @type {number}
     * @memberof StatementGetStatementsRequest
     */
    begin_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementGetStatementsRequest
     */
    end_time?: number;
    /**
     * 
     * @type {string}
     * @memberof StatementGetStatementsRequest
     */
    fields?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatementGetStatementsRequest
     */
    schemas?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof StatementGetStatementsRequest
     */
    stmt_types?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StatementGetStatementsRequest
     */
    text?: string;
}
/**
 * 
 * @export
 * @interface StatementModel
 */
export interface StatementModel {
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_affected_rows?: number;
    /**
     * avg total back off time per sql
     * @type {number}
     * @memberof StatementModel
     */
    avg_backoff_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_commit_backoff_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_commit_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_compile_latency?: number;
    /**
     * avg process time per copr task
     * @type {number}
     * @memberof StatementModel
     */
    avg_cop_process_time?: number;
    /**
     * avg wait time per copr task
     * @type {number}
     * @memberof StatementModel
     */
    avg_cop_wait_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_disk?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_get_commit_ts_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_latency?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_local_latch_wait_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_mem?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_parse_latency?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_prewrite_regions?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_prewrite_time?: number;
    /**
     * avg total process time per sql
     * @type {number}
     * @memberof StatementModel
     */
    avg_process_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_processed_keys?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_resolve_lock_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_rocksdb_block_cache_hit_count?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_rocksdb_block_read_byte?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_rocksdb_block_read_count?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_rocksdb_delete_skipped_count?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_rocksdb_key_skipped_count?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_total_keys?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_txn_retry?: number;
    /**
     * avg total wait time per sql
     * @type {number}
     * @memberof StatementModel
     */
    avg_wait_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_write_keys?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    avg_write_size?: number;
    /**
     * 
     * @type {string}
     * @memberof StatementModel
     */
    digest?: string;
    /**
     * 
     * @type {string}
     * @memberof StatementModel
     */
    digest_text?: string;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    exec_count?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    first_seen?: number;
    /**
     * 
     * @type {string}
     * @memberof StatementModel
     */
    index_names?: string;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    last_seen?: number;
    /**
     * max back off time per sql
     * @type {number}
     * @memberof StatementModel
     */
    max_backoff_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_commit_backoff_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_commit_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_compile_latency?: number;
    /**
     * max process time per copr task
     * @type {number}
     * @memberof StatementModel
     */
    max_cop_process_time?: number;
    /**
     * max wait time per copr task
     * @type {number}
     * @memberof StatementModel
     */
    max_cop_wait_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_disk?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_get_commit_ts_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_latency?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_local_latch_wait_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_mem?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_parse_latency?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_prewrite_regions?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_prewrite_time?: number;
    /**
     * max process time per sql
     * @type {number}
     * @memberof StatementModel
     */
    max_process_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_processed_keys?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_resolve_lock_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_rocksdb_block_cache_hit_count?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_rocksdb_block_read_byte?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_rocksdb_block_read_count?: number;
    /**
     * RocksDB
     * @type {number}
     * @memberof StatementModel
     */
    max_rocksdb_delete_skipped_count?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_rocksdb_key_skipped_count?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_total_keys?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_txn_retry?: number;
    /**
     * max wait time per sql
     * @type {number}
     * @memberof StatementModel
     */
    max_wait_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_write_keys?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    max_write_size?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    min_latency?: number;
    /**
     * 
     * @type {string}
     * @memberof StatementModel
     */
    plan?: string;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    plan_count?: number;
    /**
     * 
     * @type {string}
     * @memberof StatementModel
     */
    plan_digest?: string;
    /**
     * 
     * @type {string}
     * @memberof StatementModel
     */
    prev_sample_text?: string;
    /**
     * 
     * @type {string}
     * @memberof StatementModel
     */
    query_sample_text?: string;
    /**
     * Computed fields
     * @type {string}
     * @memberof StatementModel
     */
    related_schemas?: string;
    /**
     * 
     * @type {string}
     * @memberof StatementModel
     */
    sample_user?: string;
    /**
     * 
     * @type {string}
     * @memberof StatementModel
     */
    schema_name?: string;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    sum_backoff_times?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    sum_cop_task_num?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    sum_errors?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    sum_latency?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementModel
     */
    sum_warnings?: number;
    /**
     * 
     * @type {string}
     * @memberof StatementModel
     */
    table_names?: string;
}
/**
 * 
 * @export
 * @interface StatementTimeRange
 */
export interface StatementTimeRange {
    /**
     * 
     * @type {number}
     * @memberof StatementTimeRange
     */
    begin_time?: number;
    /**
     * 
     * @type {number}
     * @memberof StatementTimeRange
     */
    end_time?: number;
}
/**
 * 
 * @export
 * @interface TopologyAlertManagerInfo
 */
export interface TopologyAlertManagerInfo {
    /**
     * 
     * @type {string}
     * @memberof TopologyAlertManagerInfo
     */
    ip?: string;
    /**
     * 
     * @type {number}
     * @memberof TopologyAlertManagerInfo
     */
    port?: number;
}
/**
 * 
 * @export
 * @interface TopologyGrafanaInfo
 */
export interface TopologyGrafanaInfo {
    /**
     * 
     * @type {string}
     * @memberof TopologyGrafanaInfo
     */
    ip?: string;
    /**
     * 
     * @type {number}
     * @memberof TopologyGrafanaInfo
     */
    port?: number;
}
/**
 * 
 * @export
 * @interface TopologyPDInfo
 */
export interface TopologyPDInfo {
    /**
     * 
     * @type {string}
     * @memberof TopologyPDInfo
     */
    deploy_path?: string;
    /**
     * 
     * @type {string}
     * @memberof TopologyPDInfo
     */
    git_hash?: string;
    /**
     * 
     * @type {string}
     * @memberof TopologyPDInfo
     */
    ip?: string;
    /**
     * 
     * @type {number}
     * @memberof TopologyPDInfo
     */
    port?: number;
    /**
     * Ts = 0 means unknown
     * @type {number}
     * @memberof TopologyPDInfo
     */
    start_timestamp?: number;
    /**
     * 
     * @type {number}
     * @memberof TopologyPDInfo
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof TopologyPDInfo
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface TopologyStoreInfo
 */
export interface TopologyStoreInfo {
    /**
     * 
     * @type {string}
     * @memberof TopologyStoreInfo
     */
    deploy_path?: string;
    /**
     * 
     * @type {string}
     * @memberof TopologyStoreInfo
     */
    git_hash?: string;
    /**
     * 
     * @type {string}
     * @memberof TopologyStoreInfo
     */
    ip?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TopologyStoreInfo
     */
    labels?: { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof TopologyStoreInfo
     */
    port?: number;
    /**
     * 
     * @type {number}
     * @memberof TopologyStoreInfo
     */
    start_timestamp?: number;
    /**
     * 
     * @type {number}
     * @memberof TopologyStoreInfo
     */
    status?: number;
    /**
     * 
     * @type {number}
     * @memberof TopologyStoreInfo
     */
    status_port?: number;
    /**
     * 
     * @type {string}
     * @memberof TopologyStoreInfo
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface TopologyStoreLabels
 */
export interface TopologyStoreLabels {
    /**
     * 
     * @type {string}
     * @memberof TopologyStoreLabels
     */
    address?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof TopologyStoreLabels
     */
    labels?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface TopologyStoreLocation
 */
export interface TopologyStoreLocation {
    /**
     * 
     * @type {Array<string>}
     * @memberof TopologyStoreLocation
     */
    location_labels?: Array<string>;
    /**
     * 
     * @type {Array<TopologyStoreLabels>}
     * @memberof TopologyStoreLocation
     */
    stores?: Array<TopologyStoreLabels>;
}
/**
 * 
 * @export
 * @interface TopologyTiDBInfo
 */
export interface TopologyTiDBInfo {
    /**
     * 
     * @type {string}
     * @memberof TopologyTiDBInfo
     */
    deploy_path?: string;
    /**
     * 
     * @type {string}
     * @memberof TopologyTiDBInfo
     */
    git_hash?: string;
    /**
     * 
     * @type {string}
     * @memberof TopologyTiDBInfo
     */
    ip?: string;
    /**
     * 
     * @type {number}
     * @memberof TopologyTiDBInfo
     */
    port?: number;
    /**
     * 
     * @type {number}
     * @memberof TopologyTiDBInfo
     */
    start_timestamp?: number;
    /**
     * 
     * @type {number}
     * @memberof TopologyTiDBInfo
     */
    status?: number;
    /**
     * 
     * @type {number}
     * @memberof TopologyTiDBInfo
     */
    status_port?: number;
    /**
     * 
     * @type {string}
     * @memberof TopologyTiDBInfo
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface UserAuthenticateForm
 */
export interface UserAuthenticateForm {
    /**
     * FIXME: Use strong type
     * @type {string}
     * @memberof UserAuthenticateForm
     */
    extra?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAuthenticateForm
     */
    password?: string;
    /**
     * 
     * @type {number}
     * @memberof UserAuthenticateForm
     */
    type?: number;
    /**
     * Does not present for AuthTypeSharingCode
     * @type {string}
     * @memberof UserAuthenticateForm
     */
    username?: string;
}
/**
 * 
 * @export
 * @interface UserGetLoginInfoResponse
 */
export interface UserGetLoginInfoResponse {
    /**
     * 
     * @type {boolean}
     * @memberof UserGetLoginInfoResponse
     */
    enable_non_root_login?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof UserGetLoginInfoResponse
     */
    supported_auth_types?: Array<number>;
}
/**
 * 
 * @export
 * @interface UserGetSignOutInfoRequest
 */
export interface UserGetSignOutInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof UserGetSignOutInfoRequest
     */
    redirect_url?: string;
}
/**
 * 
 * @export
 * @interface UserSignOutInfo
 */
export interface UserSignOutInfo {
    /**
     * 
     * @type {string}
     * @memberof UserSignOutInfo
     */
    end_session_url?: string;
}
/**
 * 
 * @export
 * @interface UserTokenResponse
 */
export interface UserTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof UserTokenResponse
     */
    expire?: string;
    /**
     * 
     * @type {string}
     * @memberof UserTokenResponse
     */
    token?: string;
}
/**
 * 
 * @export
 * @interface UtilsAPIError
 */
export interface UtilsAPIError {
    /**
     * 
     * @type {string}
     * @memberof UtilsAPIError
     */
    code?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UtilsAPIError
     */
    error?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UtilsAPIError
     */
    full_text?: string;
    /**
     * 
     * @type {string}
     * @memberof UtilsAPIError
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface VersionInfo
 */
export interface VersionInfo {
    /**
     * 
     * @type {string}
     * @memberof VersionInfo
     */
    build_git_hash?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionInfo
     */
    build_time?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionInfo
     */
    internal_version?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionInfo
     */
    pd_version?: string;
    /**
     * 
     * @type {string}
     * @memberof VersionInfo
     */
    standalone?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Cancel all profling tasks with a given group ID
         * @summary Cancel all tasks with a given group ID
         * @param {string} groupId group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelProfilingGroup: async (groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling cancelProfilingGroup.');
            }
            const localVarPath = `/profiling/group/cancel/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get information of all hosts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterInfoGetHostsInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/host/all`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get cluster statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterInfoGetStatistics: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/host/statistics`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Edit a configuration
         * @param {ConfigurationEditRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationEdit: async (request: ConfigurationEditRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling configurationEdit.');
            }
            const localVarPath = `/configuration/edit`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationGetAll: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/configuration/all`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get action token for download or view profile
         * @param {string} q target query string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousProfilingActionTokenGet: async (q: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            if (q === null || q === undefined) {
                throw new RequiredError('q','Required parameter q was null or undefined when calling continuousProfilingActionTokenGet.');
            }
            const localVarPath = `/continuous_profiling/action_token`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current scraping components
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousProfilingComponentsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/continuous_profiling/components`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Continuous Profiling Config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousProfilingConfigGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/continuous_profiling/config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Continuous Profiling Config
         * @param {ProfilingNgMonitoringConfig} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousProfilingConfigPost: async (request: ProfilingNgMonitoringConfig, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling continuousProfilingConfigPost.');
            }
            const localVarPath = `/continuous_profiling/config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download Group Profile files
         * @param {number} ts timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousProfilingDownloadGet: async (ts: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ts' is not null or undefined
            if (ts === null || ts === undefined) {
                throw new RequiredError('ts','Required parameter ts was null or undefined when calling continuousProfilingDownloadGet.');
            }
            const localVarPath = `/continuous_profiling/download`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Estimate Size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousProfilingEstimateSizeGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/continuous_profiling/estimate_size`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Group Profile Detail
         * @param {number} ts timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousProfilingGroupProfileDetailGet: async (ts: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'ts' is not null or undefined
            if (ts === null || ts === undefined) {
                throw new RequiredError('ts','Required parameter ts was null or undefined when calling continuousProfilingGroupProfileDetailGet.');
            }
            const localVarPath = `/continuous_profiling/group_profile/detail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Group Profiles
         * @param {number} [beginTime] 
         * @param {number} [endTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousProfilingGroupProfilesGet: async (beginTime?: number, endTime?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/continuous_profiling/group_profiles`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (beginTime !== undefined) {
                localVarQueryParameter['begin_time'] = beginTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary View Single Profile files
         * @param {string} [address] 
         * @param {string} [component] 
         * @param {string} [profileType] 
         * @param {number} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousProfilingSingleProfileViewGet: async (address?: string, component?: string, profileType?: string, ts?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/continuous_profiling/single_profile/view`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (address !== undefined) {
                localVarQueryParameter['address'] = address;
            }

            if (component !== undefined) {
                localVarQueryParameter['component'] = component;
            }

            if (profileType !== undefined) {
                localVarQueryParameter['profile_type'] = profileType;
            }

            if (ts !== undefined) {
                localVarQueryParameter['ts'] = ts;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugAPIGetEndpoints: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/debug_api/endpoints`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Send request remote endpoint and return a token for downloading results
         * @param {EndpointRequestPayload} req request payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugAPIRequestEndpoint: async (req: EndpointRequestPayload, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling debugAPIRequestEndpoint.');
            }
            const localVarPath = `/debug_api/endpoint`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download a finished request result.
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugApiDownloadGet: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling debugApiDownloadGet.');
            }
            const localVarPath = `/debug_api/download`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete all finished profiling tasks with a given group ID
         * @summary Delete all tasks with a given group ID
         * @param {string} groupId group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfilingGroup: async (groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling deleteProfilingGroup.');
            }
            const localVarPath = `/profiling/group/delete/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate metrics relationship graph.
         * @param {DiagnoseGenerateMetricsRelationRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnoseGenerateMetricsRelationship: async (request: DiagnoseGenerateMetricsRelationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling diagnoseGenerateMetricsRelationship.');
            }
            const localVarPath = `/diagnose/metrics_relation/generate`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary View metrics relationship graph.
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnoseMetricsRelationViewGet: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling diagnoseMetricsRelationViewGet.');
            }
            const localVarPath = `/diagnose/metrics_relation/view`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get sql diagnosis reports history
         * @summary SQL diagnosis reports history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnoseReportsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/diagnose/reports`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get sql diagnosis report data
         * @summary SQL diagnosis report data
         * @param {string} id report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnoseReportsIdDataJsGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling diagnoseReportsIdDataJsGet.');
            }
            const localVarPath = `/diagnose/reports/{id}/data.js`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get sql diagnosis report HTML
         * @summary SQL diagnosis report
         * @param {string} id report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnoseReportsIdDetailGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling diagnoseReportsIdDetailGet.');
            }
            const localVarPath = `/diagnose/reports/{id}/detail`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get diagnosis report status
         * @summary Diagnosis report status
         * @param {string} id report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnoseReportsIdStatusGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling diagnoseReportsIdStatusGet.');
            }
            const localVarPath = `/diagnose/reports/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate sql diagnosis report
         * @summary SQL diagnosis report
         * @param {DiagnoseGenerateReportRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnoseReportsPost: async (request: DiagnoseGenerateReportRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling diagnoseReportsPost.');
            }
            const localVarPath = `/diagnose/reports`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download all finished profiling results of a task group
         * @summary Download all results of a task group
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadProfilingGroup: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling downloadProfilingGroup.');
            }
            const localVarPath = `/profiling/group/download`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download the finished profiling result of a task
         * @summary Download the result of a task
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadProfilingSingle: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling downloadProfilingSingle.');
            }
            const localVarPath = `/profiling/single/download`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get token with a given group ID or task ID and action type
         * @summary Get action token for download or view
         * @param {string} [id] group or task ID
         * @param {string} [action] action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionToken: async (id?: string, action?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profiling/action_token`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get current alert count from AlertManager
         * @param {string} address ip:port
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertManagerCounts: async (address: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'address' is not null or undefined
            if (address === null || address === undefined) {
                throw new RequiredError('address','Required parameter address was null or undefined when calling getAlertManagerCounts.');
            }
            const localVarPath = `/topology/alertmanager/{address}/count`
                .replace(`{${"address"}}`, encodeURIComponent(String(address)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get AlertManager instance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertManagerTopology: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/topology/alertmanager`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Grafana instance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrafanaTopology: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/topology/grafana`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all PD instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPDTopology: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/topology/pd`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all profiling tasks with a given group ID
         * @summary List all tasks with a given group ID
         * @param {string} groupId group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfilingGroupDetail: async (groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling getProfilingGroupDetail.');
            }
            const localVarPath = `/profiling/group/detail/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all profiling groups
         * @summary List all profiling groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfilingGroups: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profiling/group/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get location labels of all TiKV / TiFlash instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreLocationTopology: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/topology/store_location`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all TiKV / TiFlash instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreTopology: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/topology/store`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all TiDB instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiDBTopology: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/topology/tidb`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get information about this TiDB Dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/info/info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all databases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoListDatabases: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/info/databases`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List tables by database name
         * @param {string} [databaseName] Database name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoListTables: async (databaseName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/info/tables`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (databaseName !== undefined) {
                localVarQueryParameter['database_name'] = databaseName;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get information about current session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoWhoami: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/info/whoami`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Key Visual Dynamic Config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keyvisualConfigGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/keyvisual/config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Key Visual Dynamic Config
         * @param {ConfigKeyVisualConfig} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keyvisualConfigPut: async (request: ConfigKeyVisualConfig, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling keyvisualConfigPut.');
            }
            const localVarPath = `/keyvisual/config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Heatmaps in a given range to visualize TiKV usage
         * @summary Key Visual Heatmaps
         * @param {string} [startkey] The start of the key range
         * @param {string} [endkey] The end of the key range
         * @param {number} [starttime] The start of the time range (Unix)
         * @param {number} [endtime] The end of the time range (Unix)
         * @param {'written_bytes' | 'read_bytes' | 'written_keys' | 'read_keys' | 'integration'} [type] Main types of data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keyvisualHeatmapsGet: async (startkey?: string, endkey?: string, starttime?: number, endtime?: number, type?: 'written_bytes' | 'read_bytes' | 'written_keys' | 'read_keys' | 'integration', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/keyvisual/heatmaps`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (startkey !== undefined) {
                localVarQueryParameter['startkey'] = startkey;
            }

            if (endkey !== undefined) {
                localVarQueryParameter['endkey'] = endkey;
            }

            if (starttime !== undefined) {
                localVarQueryParameter['starttime'] = starttime;
            }

            if (endtime !== undefined) {
                localVarQueryParameter['endtime'] = endtime;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate a download token for downloading logs
         * @param {Array<string>} [id] task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsDownloadAcquireTokenGet: async (id?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/logs/download/acquire_token`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (id) {
                localVarQueryParameter['id'] = id.join(COLLECTION_FORMATS.csv);
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download logs
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsDownloadGet: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling logsDownloadGet.');
            }
            const localVarPath = `/logs/download`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create and run a new log search task group
         * @param {LogsearchCreateTaskGroupRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsTaskgroupPut: async (request: LogsearchCreateTaskGroupRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling logsTaskgroupPut.');
            }
            const localVarPath = `/logs/taskgroup`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all log search task groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsTaskgroupsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/logs/taskgroups`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel running tasks in a log search task group
         * @param {string} id task group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsTaskgroupsIdCancelPost: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling logsTaskgroupsIdCancelPost.');
            }
            const localVarPath = `/logs/taskgroups/{id}/cancel`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a log search task group
         * @param {string} id task group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsTaskgroupsIdDelete: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling logsTaskgroupsIdDelete.');
            }
            const localVarPath = `/logs/taskgroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List tasks in a log search task group
         * @param {string} id Task Group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsTaskgroupsIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling logsTaskgroupsIdGet.');
            }
            const localVarPath = `/logs/taskgroups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Preview a log search task group
         * @param {string} id task group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsTaskgroupsIdPreviewGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling logsTaskgroupsIdPreviewGet.');
            }
            const localVarPath = `/logs/taskgroups/{id}/preview`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry failed tasks in a log search task group
         * @param {string} id task group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsTaskgroupsIdRetryPost: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling logsTaskgroupsIdRetryPost.');
            }
            const localVarPath = `/logs/taskgroups/{id}/retry`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the Prometheus address cluster config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsGetPromAddress: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/metrics/prom_address`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query metrics in the given range
         * @summary Query metrics
         * @param {number} [endTimeSec] 
         * @param {string} [query] 
         * @param {number} [startTimeSec] 
         * @param {number} [stepSec] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsQueryGet: async (endTimeSec?: number, query?: string, startTimeSec?: number, stepSec?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/metrics/query`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (endTimeSec !== undefined) {
                localVarQueryParameter['end_time_sec'] = endTimeSec;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (startTimeSec !== undefined) {
                localVarQueryParameter['start_time_sec'] = startTimeSec;
            }

            if (stepSec !== undefined) {
                localVarQueryParameter['step_sec'] = stepSec;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set or clear the customized Prometheus address
         * @param {MetricsPutCustomPromAddressRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsSetCustomPromAddress: async (request: MetricsPutCustomPromAddressRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling metricsSetCustomPromAddress.');
            }
            const localVarPath = `/metrics/prom_address`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Profiling Dynamic Config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilingConfigGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profiling/config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set Profiling Dynamic Config
         * @param {ConfigProfilingConfig} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilingConfigPut: async (request: ConfigProfilingConfig, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling profilingConfigPut.');
            }
            const localVarPath = `/profiling/config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Run statements
         * @param {QueryeditorRunRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryEditorRun: async (request: QueryeditorRunRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling queryEditorRun.');
            }
            const localVarPath = `/query_editor/run`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details of a slow query
         * @param {string} [connectId] TODO: Switch back to uint64 when modern browser as well as Swagger handles BigInt well.
         * @param {string} [digest] 
         * @param {number} [timestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slowQueryDetailGet: async (connectId?: string, digest?: string, timestamp?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/slow_query/detail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (connectId !== undefined) {
                localVarQueryParameter['connect_id'] = connectId;
            }

            if (digest !== undefined) {
                localVarQueryParameter['digest'] = digest;
            }

            if (timestamp !== undefined) {
                localVarQueryParameter['timestamp'] = timestamp;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download slow query statements
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slowQueryDownloadGet: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling slowQueryDownloadGet.');
            }
            const localVarPath = `/slow_query/download`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate a download token for exported slow query statements
         * @param {SlowqueryGetListRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slowQueryDownloadTokenPost: async (request: SlowqueryGetListRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling slowQueryDownloadTokenPost.');
            }
            const localVarPath = `/slow_query/download/token`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all slow queries
         * @param {number} [beginTime] 
         * @param {Array<string>} [db] 
         * @param {boolean} [desc] 
         * @param {string} [digest] 
         * @param {number} [endTime] 
         * @param {string} [fields] example: \&quot;Query,Digest\&quot;
         * @param {number} [limit] 
         * @param {string} [orderBy] 
         * @param {Array<string>} [plans] for showing slow queries in the statement detail page
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slowQueryListGet: async (beginTime?: number, db?: Array<string>, desc?: boolean, digest?: string, endTime?: number, fields?: string, limit?: number, orderBy?: string, plans?: Array<string>, text?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/slow_query/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (beginTime !== undefined) {
                localVarQueryParameter['begin_time'] = beginTime;
            }

            if (db) {
                localVarQueryParameter['db'] = db;
            }

            if (desc !== undefined) {
                localVarQueryParameter['desc'] = desc;
            }

            if (digest !== undefined) {
                localVarQueryParameter['digest'] = digest;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (plans) {
                localVarQueryParameter['plans'] = plans;
            }

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query slowquery table columns
         * @summary Query table columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slowQueryTableColumnsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/slow_query/table_columns`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Start a profiling task group
         * @summary Start profiling
         * @param {ProfilingStartRequest} req profiling request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startProfiling: async (req: ProfilingStartRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            if (req === null || req === undefined) {
                throw new RequiredError('req','Required parameter req was null or undefined when calling startProfiling.');
            }
            const localVarPath = `/profiling/group/start`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof req !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(req !== undefined ? req : {}) : (req || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get statement configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsConfigGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/statements/config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update statement configurations
         * @param {StatementEditableConfig} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsConfigPost: async (request: StatementEditableConfig, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling statementsConfigPost.');
            }
            const localVarPath = `/statements/config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download statements
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsDownloadGet: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling statementsDownloadGet.');
            }
            const localVarPath = `/statements/download`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate a download token for exported statements
         * @param {StatementGetStatementsRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsDownloadTokenPost: async (request: StatementGetStatementsRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling statementsDownloadTokenPost.');
            }
            const localVarPath = `/statements/download/token`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of statements
         * @param {number} [beginTime] 
         * @param {number} [endTime] 
         * @param {string} [fields] 
         * @param {Array<string>} [schemas] 
         * @param {Array<string>} [stmtTypes] 
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsListGet: async (beginTime?: number, endTime?: number, fields?: string, schemas?: Array<string>, stmtTypes?: Array<string>, text?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/statements/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (beginTime !== undefined) {
                localVarQueryParameter['begin_time'] = beginTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (schemas) {
                localVarQueryParameter['schemas'] = schemas;
            }

            if (stmtTypes) {
                localVarQueryParameter['stmt_types'] = stmtTypes;
            }

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get details of a statement in an execution plan
         * @param {number} [beginTime] 
         * @param {string} [digest] 
         * @param {number} [endTime] 
         * @param {Array<string>} [plans] 
         * @param {string} [schemaName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsPlanDetailGet: async (beginTime?: number, digest?: string, endTime?: number, plans?: Array<string>, schemaName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/statements/plan/detail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (beginTime !== undefined) {
                localVarQueryParameter['begin_time'] = beginTime;
            }

            if (digest !== undefined) {
                localVarQueryParameter['digest'] = digest;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }

            if (plans) {
                localVarQueryParameter['plans'] = plans;
            }

            if (schemaName !== undefined) {
                localVarQueryParameter['schema_name'] = schemaName;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get execution plans of a statement
         * @param {number} [beginTime] 
         * @param {string} [digest] 
         * @param {number} [endTime] 
         * @param {string} [schemaName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsPlansGet: async (beginTime?: number, digest?: string, endTime?: number, schemaName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/statements/plans`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (beginTime !== undefined) {
                localVarQueryParameter['begin_time'] = beginTime;
            }

            if (digest !== undefined) {
                localVarQueryParameter['digest'] = digest;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }

            if (schemaName !== undefined) {
                localVarQueryParameter['schema_name'] = schemaName;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all statement types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsStmtTypesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/statements/stmt_types`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query statements table columns
         * @summary Query table columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsTableColumnsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/statements/table_columns`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available statement time ranges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsTimeRangesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/statements/time_ranges`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Hide a TiDB instance
         * @param {string} address ip:port
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topologyTidbAddressDelete: async (address: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'address' is not null or undefined
            if (address === null || address === undefined) {
                throw new RequiredError('address','Required parameter address was null or undefined when calling topologyTidbAddressDelete.');
            }
            const localVarPath = `/topology/tidb/{address}`
                .replace(`{${"address"}}`, encodeURIComponent(String(address)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get log in information, like supported authenticate types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetLoginInfo: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/login_info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get sign out info
         * @param {string} [redirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetSignOutInfo: async (redirectUrl?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/sign_out_info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (redirectUrl !== undefined) {
                localVarQueryParameter['redirect_url'] = redirectUrl;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Log in
         * @param {UserAuthenticateForm} message Credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLogin: async (message: UserAuthenticateForm, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'message' is not null or undefined
            if (message === null || message === undefined) {
                throw new RequiredError('message','Required parameter message was null or undefined when calling userLogin.');
            }
            const localVarPath = `/user/login`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof message !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(message !== undefined ? message : {}) : (message || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an impersonation
         * @param {SsoCreateImpersonationRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSSOCreateImpersonation: async (request: SsoCreateImpersonationRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling userSSOCreateImpersonation.');
            }
            const localVarPath = `/user/sso/impersonation`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get SSO Auth URL
         * @param {string} [codeVerifier] 
         * @param {string} [redirectUrl] 
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSSOGetAuthURL: async (codeVerifier?: string, redirectUrl?: string, state?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/sso/auth_url`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (codeVerifier !== undefined) {
                localVarQueryParameter['code_verifier'] = codeVerifier;
            }

            if (redirectUrl !== undefined) {
                localVarQueryParameter['redirect_url'] = redirectUrl;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get SSO config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSSOGetConfig: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/sso/config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all impersonations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSSOListImpersonations: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/sso/impersonations/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set SSO config
         * @param {SsoSetConfigRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSSOSetConfig: async (request: SsoSetConfigRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling userSSOSetConfig.');
            }
            const localVarPath = `/user/sso/config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Share current session and generate a sharing code
         * @param {CodeShareRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userShareSession: async (request: CodeShareRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling userShareSession.');
            }
            const localVarPath = `/user/share/code`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const needsSerialization = (typeof request !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * View the finished profiling result of a task
         * @summary View the result of a task
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewProfilingSingle: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling viewProfilingSingle.');
            }
            const localVarPath = `/profiling/single/view`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JwtAuth required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }


    
            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Cancel all profling tasks with a given group ID
         * @summary Cancel all tasks with a given group ID
         * @param {string} groupId group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelProfilingGroup(groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).cancelProfilingGroup(groupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get information of all hosts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterInfoGetHostsInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterinfoGetHostsInfoResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).clusterInfoGetHostsInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get cluster statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterInfoGetStatistics(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterinfoClusterStatistics>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).clusterInfoGetStatistics(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Edit a configuration
         * @param {ConfigurationEditRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationEdit(request: ConfigurationEditRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigurationEditResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).configurationEdit(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async configurationGetAll(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigurationAllConfigItems>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).configurationGetAll(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get action token for download or view profile
         * @param {string} q target query string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continuousProfilingActionTokenGet(q: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).continuousProfilingActionTokenGet(q, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get current scraping components
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continuousProfilingComponentsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProfilingComponent>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).continuousProfilingComponentsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Continuous Profiling Config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continuousProfilingConfigGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfilingNgMonitoringConfig>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).continuousProfilingConfigGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update Continuous Profiling Config
         * @param {ProfilingNgMonitoringConfig} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continuousProfilingConfigPost(request: ProfilingNgMonitoringConfig, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).continuousProfilingConfigPost(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Download Group Profile files
         * @param {number} ts timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continuousProfilingDownloadGet(ts: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).continuousProfilingDownloadGet(ts, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Estimate Size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continuousProfilingEstimateSizeGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfilingEstimateSizeRes>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).continuousProfilingEstimateSizeGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Group Profile Detail
         * @param {number} ts timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continuousProfilingGroupProfileDetailGet(ts: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfilingGroupProfileDetail>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).continuousProfilingGroupProfileDetailGet(ts, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Group Profiles
         * @param {number} [beginTime] 
         * @param {number} [endTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continuousProfilingGroupProfilesGet(beginTime?: number, endTime?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProfilingGroupProfiles>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).continuousProfilingGroupProfilesGet(beginTime, endTime, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary View Single Profile files
         * @param {string} [address] 
         * @param {string} [component] 
         * @param {string} [profileType] 
         * @param {number} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async continuousProfilingSingleProfileViewGet(address?: string, component?: string, profileType?: string, ts?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).continuousProfilingSingleProfileViewGet(address, component, profileType, ts, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async debugAPIGetEndpoints(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EndpointAPIModel>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).debugAPIGetEndpoints(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Send request remote endpoint and return a token for downloading results
         * @param {EndpointRequestPayload} req request payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async debugAPIRequestEndpoint(req: EndpointRequestPayload, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).debugAPIRequestEndpoint(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Download a finished request result.
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async debugApiDownloadGet(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).debugApiDownloadGet(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Delete all finished profiling tasks with a given group ID
         * @summary Delete all tasks with a given group ID
         * @param {string} groupId group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProfilingGroup(groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).deleteProfilingGroup(groupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Generate metrics relationship graph.
         * @param {DiagnoseGenerateMetricsRelationRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async diagnoseGenerateMetricsRelationship(request: DiagnoseGenerateMetricsRelationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).diagnoseGenerateMetricsRelationship(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary View metrics relationship graph.
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async diagnoseMetricsRelationViewGet(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).diagnoseMetricsRelationViewGet(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get sql diagnosis reports history
         * @summary SQL diagnosis reports history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async diagnoseReportsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiagnoseReport>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).diagnoseReportsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get sql diagnosis report data
         * @summary SQL diagnosis report data
         * @param {string} id report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async diagnoseReportsIdDataJsGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).diagnoseReportsIdDataJsGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get sql diagnosis report HTML
         * @summary SQL diagnosis report
         * @param {string} id report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async diagnoseReportsIdDetailGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).diagnoseReportsIdDetailGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get diagnosis report status
         * @summary Diagnosis report status
         * @param {string} id report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async diagnoseReportsIdStatusGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiagnoseReport>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).diagnoseReportsIdStatusGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Generate sql diagnosis report
         * @summary SQL diagnosis report
         * @param {DiagnoseGenerateReportRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async diagnoseReportsPost(request: DiagnoseGenerateReportRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).diagnoseReportsPost(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Download all finished profiling results of a task group
         * @summary Download all results of a task group
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadProfilingGroup(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).downloadProfilingGroup(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Download the finished profiling result of a task
         * @summary Download the result of a task
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadProfilingSingle(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).downloadProfilingSingle(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Get token with a given group ID or task ID and action type
         * @summary Get action token for download or view
         * @param {string} [id] group or task ID
         * @param {string} [action] action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActionToken(id?: string, action?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getActionToken(id, action, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get current alert count from AlertManager
         * @param {string} address ip:port
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlertManagerCounts(address: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAlertManagerCounts(address, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get AlertManager instance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAlertManagerTopology(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopologyAlertManagerInfo>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getAlertManagerTopology(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Grafana instance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGrafanaTopology(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopologyGrafanaInfo>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getGrafanaTopology(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all PD instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPDTopology(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopologyPDInfo>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getPDTopology(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List all profiling tasks with a given group ID
         * @summary List all tasks with a given group ID
         * @param {string} groupId group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfilingGroupDetail(groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfilingGroupDetailResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProfilingGroupDetail(groupId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * List all profiling groups
         * @summary List all profiling groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfilingGroups(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProfilingTaskGroupModel>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getProfilingGroups(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get location labels of all TiKV / TiFlash instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreLocationTopology(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TopologyStoreLocation>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getStoreLocationTopology(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all TiKV / TiFlash instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStoreTopology(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClusterinfoStoreTopologyResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getStoreTopology(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all TiDB instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTiDBTopology(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TopologyTiDBInfo>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).getTiDBTopology(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get information about this TiDB Dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoInfoResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).infoGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List all databases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoListDatabases(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).infoListDatabases(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List tables by database name
         * @param {string} [databaseName] Database name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoListTables(databaseName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InfoTableSchema>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).infoListTables(databaseName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get information about current session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async infoWhoami(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InfoWhoAmIResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).infoWhoami(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Key Visual Dynamic Config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keyvisualConfigGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigKeyVisualConfig>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).keyvisualConfigGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Set Key Visual Dynamic Config
         * @param {ConfigKeyVisualConfig} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keyvisualConfigPut(request: ConfigKeyVisualConfig, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigKeyVisualConfig>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).keyvisualConfigPut(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Heatmaps in a given range to visualize TiKV usage
         * @summary Key Visual Heatmaps
         * @param {string} [startkey] The start of the key range
         * @param {string} [endkey] The end of the key range
         * @param {number} [starttime] The start of the time range (Unix)
         * @param {number} [endtime] The end of the time range (Unix)
         * @param {'written_bytes' | 'read_bytes' | 'written_keys' | 'read_keys' | 'integration'} [type] Main types of data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async keyvisualHeatmapsGet(startkey?: string, endkey?: string, starttime?: number, endtime?: number, type?: 'written_bytes' | 'read_bytes' | 'written_keys' | 'read_keys' | 'integration', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatrixMatrix>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).keyvisualHeatmapsGet(startkey, endkey, starttime, endtime, type, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Generate a download token for downloading logs
         * @param {Array<string>} [id] task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logsDownloadAcquireTokenGet(id?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).logsDownloadAcquireTokenGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Download logs
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logsDownloadGet(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).logsDownloadGet(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create and run a new log search task group
         * @param {LogsearchCreateTaskGroupRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logsTaskgroupPut(request: LogsearchCreateTaskGroupRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogsearchTaskGroupResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).logsTaskgroupPut(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List all log search task groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logsTaskgroupsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LogsearchTaskGroupModel>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).logsTaskgroupsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Cancel running tasks in a log search task group
         * @param {string} id task group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logsTaskgroupsIdCancelPost(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).logsTaskgroupsIdCancelPost(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Delete a log search task group
         * @param {string} id task group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logsTaskgroupsIdDelete(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).logsTaskgroupsIdDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List tasks in a log search task group
         * @param {string} id Task Group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logsTaskgroupsIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogsearchTaskGroupResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).logsTaskgroupsIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Preview a log search task group
         * @param {string} id task group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logsTaskgroupsIdPreviewGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LogsearchPreviewModel>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).logsTaskgroupsIdPreviewGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Retry failed tasks in a log search task group
         * @param {string} id task group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logsTaskgroupsIdRetryPost(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).logsTaskgroupsIdRetryPost(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the Prometheus address cluster config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metricsGetPromAddress(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricsGetPromAddressConfigResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).metricsGetPromAddress(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Query metrics in the given range
         * @summary Query metrics
         * @param {number} [endTimeSec] 
         * @param {string} [query] 
         * @param {number} [startTimeSec] 
         * @param {number} [stepSec] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metricsQueryGet(endTimeSec?: number, query?: string, startTimeSec?: number, stepSec?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricsQueryResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).metricsQueryGet(endTimeSec, query, startTimeSec, stepSec, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Set or clear the customized Prometheus address
         * @param {MetricsPutCustomPromAddressRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async metricsSetCustomPromAddress(request: MetricsPutCustomPromAddressRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricsPutCustomPromAddressResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).metricsSetCustomPromAddress(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get Profiling Dynamic Config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profilingConfigGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigProfilingConfig>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).profilingConfigGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Set Profiling Dynamic Config
         * @param {ConfigProfilingConfig} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async profilingConfigPut(request: ConfigProfilingConfig, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigProfilingConfig>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).profilingConfigPut(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Run statements
         * @param {QueryeditorRunRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryEditorRun(request: QueryeditorRunRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueryeditorRunResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).queryEditorRun(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get details of a slow query
         * @param {string} [connectId] TODO: Switch back to uint64 when modern browser as well as Swagger handles BigInt well.
         * @param {string} [digest] 
         * @param {number} [timestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slowQueryDetailGet(connectId?: string, digest?: string, timestamp?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlowqueryModel>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).slowQueryDetailGet(connectId, digest, timestamp, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Download slow query statements
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slowQueryDownloadGet(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).slowQueryDownloadGet(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Generate a download token for exported slow query statements
         * @param {SlowqueryGetListRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slowQueryDownloadTokenPost(request: SlowqueryGetListRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).slowQueryDownloadTokenPost(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List all slow queries
         * @param {number} [beginTime] 
         * @param {Array<string>} [db] 
         * @param {boolean} [desc] 
         * @param {string} [digest] 
         * @param {number} [endTime] 
         * @param {string} [fields] example: \&quot;Query,Digest\&quot;
         * @param {number} [limit] 
         * @param {string} [orderBy] 
         * @param {Array<string>} [plans] for showing slow queries in the statement detail page
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slowQueryListGet(beginTime?: number, db?: Array<string>, desc?: boolean, digest?: string, endTime?: number, fields?: string, limit?: number, orderBy?: string, plans?: Array<string>, text?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SlowqueryModel>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).slowQueryListGet(beginTime, db, desc, digest, endTime, fields, limit, orderBy, plans, text, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Query slowquery table columns
         * @summary Query table columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async slowQueryTableColumnsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).slowQueryTableColumnsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Start a profiling task group
         * @summary Start profiling
         * @param {ProfilingStartRequest} req profiling request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startProfiling(req: ProfilingStartRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProfilingTaskGroupModel>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).startProfiling(req, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get statement configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementsConfigGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatementEditableConfig>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).statementsConfigGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update statement configurations
         * @param {StatementEditableConfig} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementsConfigPost(request: StatementEditableConfig, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).statementsConfigPost(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Download statements
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementsDownloadGet(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).statementsDownloadGet(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Generate a download token for exported statements
         * @param {StatementGetStatementsRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementsDownloadTokenPost(request: StatementGetStatementsRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).statementsDownloadTokenPost(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get a list of statements
         * @param {number} [beginTime] 
         * @param {number} [endTime] 
         * @param {string} [fields] 
         * @param {Array<string>} [schemas] 
         * @param {Array<string>} [stmtTypes] 
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementsListGet(beginTime?: number, endTime?: number, fields?: string, schemas?: Array<string>, stmtTypes?: Array<string>, text?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatementModel>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).statementsListGet(beginTime, endTime, fields, schemas, stmtTypes, text, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get details of a statement in an execution plan
         * @param {number} [beginTime] 
         * @param {string} [digest] 
         * @param {number} [endTime] 
         * @param {Array<string>} [plans] 
         * @param {string} [schemaName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementsPlanDetailGet(beginTime?: number, digest?: string, endTime?: number, plans?: Array<string>, schemaName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatementModel>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).statementsPlanDetailGet(beginTime, digest, endTime, plans, schemaName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get execution plans of a statement
         * @param {number} [beginTime] 
         * @param {string} [digest] 
         * @param {number} [endTime] 
         * @param {string} [schemaName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementsPlansGet(beginTime?: number, digest?: string, endTime?: number, schemaName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatementModel>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).statementsPlansGet(beginTime, digest, endTime, schemaName, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get all statement types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementsStmtTypesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).statementsStmtTypesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * Query statements table columns
         * @summary Query table columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementsTableColumnsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).statementsTableColumnsGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get available statement time ranges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statementsTimeRangesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatementTimeRange>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).statementsTimeRangesGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Hide a TiDB instance
         * @param {string} address ip:port
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async topologyTidbAddressDelete(address: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).topologyTidbAddressDelete(address, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get log in information, like supported authenticate types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetLoginInfo(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserGetLoginInfoResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).userGetLoginInfo(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get sign out info
         * @param {string} [redirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetSignOutInfo(redirectUrl?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSignOutInfo>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).userGetSignOutInfo(redirectUrl, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Log in
         * @param {UserAuthenticateForm} message Credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userLogin(message: UserAuthenticateForm, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserTokenResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).userLogin(message, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Create an impersonation
         * @param {SsoCreateImpersonationRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSSOCreateImpersonation(request: SsoCreateImpersonationRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SsoSSOImpersonationModel>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).userSSOCreateImpersonation(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get SSO Auth URL
         * @param {string} [codeVerifier] 
         * @param {string} [redirectUrl] 
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSSOGetAuthURL(codeVerifier?: string, redirectUrl?: string, state?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).userSSOGetAuthURL(codeVerifier, redirectUrl, state, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get SSO config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSSOGetConfig(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigSSOCoreConfig>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).userSSOGetConfig(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary List all impersonations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSSOListImpersonations(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SsoSSOImpersonationModel>>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).userSSOListImpersonations(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Set SSO config
         * @param {SsoSetConfigRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userSSOSetConfig(request: SsoSetConfigRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigSSOCoreConfig>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).userSSOSetConfig(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Share current session and generate a sharing code
         * @param {CodeShareRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userShareSession(request: CodeShareRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CodeShareResponse>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).userShareSession(request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * View the finished profiling result of a task
         * @summary View the result of a task
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async viewProfilingSingle(token: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).viewProfilingSingle(token, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * Cancel all profling tasks with a given group ID
         * @summary Cancel all tasks with a given group ID
         * @param {string} groupId group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelProfilingGroup(groupId: string, options?: any): AxiosPromise<object> {
            return DefaultApiFp(configuration).cancelProfilingGroup(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get information of all hosts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterInfoGetHostsInfo(options?: any): AxiosPromise<ClusterinfoGetHostsInfoResponse> {
            return DefaultApiFp(configuration).clusterInfoGetHostsInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get cluster statistics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterInfoGetStatistics(options?: any): AxiosPromise<ClusterinfoClusterStatistics> {
            return DefaultApiFp(configuration).clusterInfoGetStatistics(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Edit a configuration
         * @param {ConfigurationEditRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationEdit(request: ConfigurationEditRequest, options?: any): AxiosPromise<ConfigurationEditResponse> {
            return DefaultApiFp(configuration).configurationEdit(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        configurationGetAll(options?: any): AxiosPromise<ConfigurationAllConfigItems> {
            return DefaultApiFp(configuration).configurationGetAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get action token for download or view profile
         * @param {string} q target query string
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousProfilingActionTokenGet(q: string, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).continuousProfilingActionTokenGet(q, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current scraping components
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousProfilingComponentsGet(options?: any): AxiosPromise<Array<ProfilingComponent>> {
            return DefaultApiFp(configuration).continuousProfilingComponentsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Continuous Profiling Config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousProfilingConfigGet(options?: any): AxiosPromise<ProfilingNgMonitoringConfig> {
            return DefaultApiFp(configuration).continuousProfilingConfigGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Continuous Profiling Config
         * @param {ProfilingNgMonitoringConfig} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousProfilingConfigPost(request: ProfilingNgMonitoringConfig, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).continuousProfilingConfigPost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download Group Profile files
         * @param {number} ts timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousProfilingDownloadGet(ts: number, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).continuousProfilingDownloadGet(ts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Estimate Size
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousProfilingEstimateSizeGet(options?: any): AxiosPromise<ProfilingEstimateSizeRes> {
            return DefaultApiFp(configuration).continuousProfilingEstimateSizeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Group Profile Detail
         * @param {number} ts timestamp
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousProfilingGroupProfileDetailGet(ts: number, options?: any): AxiosPromise<ProfilingGroupProfileDetail> {
            return DefaultApiFp(configuration).continuousProfilingGroupProfileDetailGet(ts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Group Profiles
         * @param {number} [beginTime] 
         * @param {number} [endTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousProfilingGroupProfilesGet(beginTime?: number, endTime?: number, options?: any): AxiosPromise<Array<ProfilingGroupProfiles>> {
            return DefaultApiFp(configuration).continuousProfilingGroupProfilesGet(beginTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary View Single Profile files
         * @param {string} [address] 
         * @param {string} [component] 
         * @param {string} [profileType] 
         * @param {number} [ts] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        continuousProfilingSingleProfileViewGet(address?: string, component?: string, profileType?: string, ts?: number, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).continuousProfilingSingleProfileViewGet(address, component, profileType, ts, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all endpoints
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugAPIGetEndpoints(options?: any): AxiosPromise<Array<EndpointAPIModel>> {
            return DefaultApiFp(configuration).debugAPIGetEndpoints(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Send request remote endpoint and return a token for downloading results
         * @param {EndpointRequestPayload} req request payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugAPIRequestEndpoint(req: EndpointRequestPayload, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).debugAPIRequestEndpoint(req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download a finished request result.
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        debugApiDownloadGet(token: string, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).debugApiDownloadGet(token, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete all finished profiling tasks with a given group ID
         * @summary Delete all tasks with a given group ID
         * @param {string} groupId group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProfilingGroup(groupId: string, options?: any): AxiosPromise<object> {
            return DefaultApiFp(configuration).deleteProfilingGroup(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate metrics relationship graph.
         * @param {DiagnoseGenerateMetricsRelationRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnoseGenerateMetricsRelationship(request: DiagnoseGenerateMetricsRelationRequest, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).diagnoseGenerateMetricsRelationship(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary View metrics relationship graph.
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnoseMetricsRelationViewGet(token: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).diagnoseMetricsRelationViewGet(token, options).then((request) => request(axios, basePath));
        },
        /**
         * Get sql diagnosis reports history
         * @summary SQL diagnosis reports history
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnoseReportsGet(options?: any): AxiosPromise<Array<DiagnoseReport>> {
            return DefaultApiFp(configuration).diagnoseReportsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get sql diagnosis report data
         * @summary SQL diagnosis report data
         * @param {string} id report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnoseReportsIdDataJsGet(id: string, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).diagnoseReportsIdDataJsGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get sql diagnosis report HTML
         * @summary SQL diagnosis report
         * @param {string} id report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnoseReportsIdDetailGet(id: string, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).diagnoseReportsIdDetailGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get diagnosis report status
         * @summary Diagnosis report status
         * @param {string} id report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnoseReportsIdStatusGet(id: string, options?: any): AxiosPromise<DiagnoseReport> {
            return DefaultApiFp(configuration).diagnoseReportsIdStatusGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate sql diagnosis report
         * @summary SQL diagnosis report
         * @param {DiagnoseGenerateReportRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diagnoseReportsPost(request: DiagnoseGenerateReportRequest, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).diagnoseReportsPost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * Download all finished profiling results of a task group
         * @summary Download all results of a task group
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadProfilingGroup(token: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).downloadProfilingGroup(token, options).then((request) => request(axios, basePath));
        },
        /**
         * Download the finished profiling result of a task
         * @summary Download the result of a task
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadProfilingSingle(token: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).downloadProfilingSingle(token, options).then((request) => request(axios, basePath));
        },
        /**
         * Get token with a given group ID or task ID and action type
         * @summary Get action token for download or view
         * @param {string} [id] group or task ID
         * @param {string} [action] action
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActionToken(id?: string, action?: string, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).getActionToken(id, action, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get current alert count from AlertManager
         * @param {string} address ip:port
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertManagerCounts(address: string, options?: any): AxiosPromise<number> {
            return DefaultApiFp(configuration).getAlertManagerCounts(address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get AlertManager instance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlertManagerTopology(options?: any): AxiosPromise<TopologyAlertManagerInfo> {
            return DefaultApiFp(configuration).getAlertManagerTopology(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Grafana instance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrafanaTopology(options?: any): AxiosPromise<TopologyGrafanaInfo> {
            return DefaultApiFp(configuration).getGrafanaTopology(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all PD instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPDTopology(options?: any): AxiosPromise<Array<TopologyPDInfo>> {
            return DefaultApiFp(configuration).getPDTopology(options).then((request) => request(axios, basePath));
        },
        /**
         * List all profiling tasks with a given group ID
         * @summary List all tasks with a given group ID
         * @param {string} groupId group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfilingGroupDetail(groupId: string, options?: any): AxiosPromise<ProfilingGroupDetailResponse> {
            return DefaultApiFp(configuration).getProfilingGroupDetail(groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * List all profiling groups
         * @summary List all profiling groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfilingGroups(options?: any): AxiosPromise<Array<ProfilingTaskGroupModel>> {
            return DefaultApiFp(configuration).getProfilingGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get location labels of all TiKV / TiFlash instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreLocationTopology(options?: any): AxiosPromise<TopologyStoreLocation> {
            return DefaultApiFp(configuration).getStoreLocationTopology(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all TiKV / TiFlash instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStoreTopology(options?: any): AxiosPromise<ClusterinfoStoreTopologyResponse> {
            return DefaultApiFp(configuration).getStoreTopology(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all TiDB instances
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTiDBTopology(options?: any): AxiosPromise<Array<TopologyTiDBInfo>> {
            return DefaultApiFp(configuration).getTiDBTopology(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get information about this TiDB Dashboard
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoGet(options?: any): AxiosPromise<InfoInfoResponse> {
            return DefaultApiFp(configuration).infoGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all databases
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoListDatabases(options?: any): AxiosPromise<Array<string>> {
            return DefaultApiFp(configuration).infoListDatabases(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List tables by database name
         * @param {string} [databaseName] Database name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoListTables(databaseName?: string, options?: any): AxiosPromise<Array<InfoTableSchema>> {
            return DefaultApiFp(configuration).infoListTables(databaseName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get information about current session
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        infoWhoami(options?: any): AxiosPromise<InfoWhoAmIResponse> {
            return DefaultApiFp(configuration).infoWhoami(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Key Visual Dynamic Config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keyvisualConfigGet(options?: any): AxiosPromise<ConfigKeyVisualConfig> {
            return DefaultApiFp(configuration).keyvisualConfigGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Key Visual Dynamic Config
         * @param {ConfigKeyVisualConfig} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keyvisualConfigPut(request: ConfigKeyVisualConfig, options?: any): AxiosPromise<ConfigKeyVisualConfig> {
            return DefaultApiFp(configuration).keyvisualConfigPut(request, options).then((request) => request(axios, basePath));
        },
        /**
         * Heatmaps in a given range to visualize TiKV usage
         * @summary Key Visual Heatmaps
         * @param {string} [startkey] The start of the key range
         * @param {string} [endkey] The end of the key range
         * @param {number} [starttime] The start of the time range (Unix)
         * @param {number} [endtime] The end of the time range (Unix)
         * @param {'written_bytes' | 'read_bytes' | 'written_keys' | 'read_keys' | 'integration'} [type] Main types of data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        keyvisualHeatmapsGet(startkey?: string, endkey?: string, starttime?: number, endtime?: number, type?: 'written_bytes' | 'read_bytes' | 'written_keys' | 'read_keys' | 'integration', options?: any): AxiosPromise<MatrixMatrix> {
            return DefaultApiFp(configuration).keyvisualHeatmapsGet(startkey, endkey, starttime, endtime, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate a download token for downloading logs
         * @param {Array<string>} [id] task id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsDownloadAcquireTokenGet(id?: Array<string>, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).logsDownloadAcquireTokenGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download logs
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsDownloadGet(token: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).logsDownloadGet(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create and run a new log search task group
         * @param {LogsearchCreateTaskGroupRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsTaskgroupPut(request: LogsearchCreateTaskGroupRequest, options?: any): AxiosPromise<LogsearchTaskGroupResponse> {
            return DefaultApiFp(configuration).logsTaskgroupPut(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all log search task groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsTaskgroupsGet(options?: any): AxiosPromise<Array<LogsearchTaskGroupModel>> {
            return DefaultApiFp(configuration).logsTaskgroupsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Cancel running tasks in a log search task group
         * @param {string} id task group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsTaskgroupsIdCancelPost(id: string, options?: any): AxiosPromise<object> {
            return DefaultApiFp(configuration).logsTaskgroupsIdCancelPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a log search task group
         * @param {string} id task group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsTaskgroupsIdDelete(id: string, options?: any): AxiosPromise<object> {
            return DefaultApiFp(configuration).logsTaskgroupsIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List tasks in a log search task group
         * @param {string} id Task Group ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsTaskgroupsIdGet(id: string, options?: any): AxiosPromise<LogsearchTaskGroupResponse> {
            return DefaultApiFp(configuration).logsTaskgroupsIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Preview a log search task group
         * @param {string} id task group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsTaskgroupsIdPreviewGet(id: string, options?: any): AxiosPromise<Array<LogsearchPreviewModel>> {
            return DefaultApiFp(configuration).logsTaskgroupsIdPreviewGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry failed tasks in a log search task group
         * @param {string} id task group id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logsTaskgroupsIdRetryPost(id: string, options?: any): AxiosPromise<object> {
            return DefaultApiFp(configuration).logsTaskgroupsIdRetryPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get the Prometheus address cluster config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsGetPromAddress(options?: any): AxiosPromise<MetricsGetPromAddressConfigResponse> {
            return DefaultApiFp(configuration).metricsGetPromAddress(options).then((request) => request(axios, basePath));
        },
        /**
         * Query metrics in the given range
         * @summary Query metrics
         * @param {number} [endTimeSec] 
         * @param {string} [query] 
         * @param {number} [startTimeSec] 
         * @param {number} [stepSec] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsQueryGet(endTimeSec?: number, query?: string, startTimeSec?: number, stepSec?: number, options?: any): AxiosPromise<MetricsQueryResponse> {
            return DefaultApiFp(configuration).metricsQueryGet(endTimeSec, query, startTimeSec, stepSec, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set or clear the customized Prometheus address
         * @param {MetricsPutCustomPromAddressRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        metricsSetCustomPromAddress(request: MetricsPutCustomPromAddressRequest, options?: any): AxiosPromise<MetricsPutCustomPromAddressResponse> {
            return DefaultApiFp(configuration).metricsSetCustomPromAddress(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Profiling Dynamic Config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilingConfigGet(options?: any): AxiosPromise<ConfigProfilingConfig> {
            return DefaultApiFp(configuration).profilingConfigGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set Profiling Dynamic Config
         * @param {ConfigProfilingConfig} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profilingConfigPut(request: ConfigProfilingConfig, options?: any): AxiosPromise<ConfigProfilingConfig> {
            return DefaultApiFp(configuration).profilingConfigPut(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Run statements
         * @param {QueryeditorRunRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryEditorRun(request: QueryeditorRunRequest, options?: any): AxiosPromise<QueryeditorRunResponse> {
            return DefaultApiFp(configuration).queryEditorRun(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details of a slow query
         * @param {string} [connectId] TODO: Switch back to uint64 when modern browser as well as Swagger handles BigInt well.
         * @param {string} [digest] 
         * @param {number} [timestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slowQueryDetailGet(connectId?: string, digest?: string, timestamp?: number, options?: any): AxiosPromise<SlowqueryModel> {
            return DefaultApiFp(configuration).slowQueryDetailGet(connectId, digest, timestamp, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download slow query statements
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slowQueryDownloadGet(token: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).slowQueryDownloadGet(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate a download token for exported slow query statements
         * @param {SlowqueryGetListRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slowQueryDownloadTokenPost(request: SlowqueryGetListRequest, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).slowQueryDownloadTokenPost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all slow queries
         * @param {number} [beginTime] 
         * @param {Array<string>} [db] 
         * @param {boolean} [desc] 
         * @param {string} [digest] 
         * @param {number} [endTime] 
         * @param {string} [fields] example: \&quot;Query,Digest\&quot;
         * @param {number} [limit] 
         * @param {string} [orderBy] 
         * @param {Array<string>} [plans] for showing slow queries in the statement detail page
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slowQueryListGet(beginTime?: number, db?: Array<string>, desc?: boolean, digest?: string, endTime?: number, fields?: string, limit?: number, orderBy?: string, plans?: Array<string>, text?: string, options?: any): AxiosPromise<Array<SlowqueryModel>> {
            return DefaultApiFp(configuration).slowQueryListGet(beginTime, db, desc, digest, endTime, fields, limit, orderBy, plans, text, options).then((request) => request(axios, basePath));
        },
        /**
         * Query slowquery table columns
         * @summary Query table columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        slowQueryTableColumnsGet(options?: any): AxiosPromise<Array<string>> {
            return DefaultApiFp(configuration).slowQueryTableColumnsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Start a profiling task group
         * @summary Start profiling
         * @param {ProfilingStartRequest} req profiling request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startProfiling(req: ProfilingStartRequest, options?: any): AxiosPromise<ProfilingTaskGroupModel> {
            return DefaultApiFp(configuration).startProfiling(req, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get statement configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsConfigGet(options?: any): AxiosPromise<StatementEditableConfig> {
            return DefaultApiFp(configuration).statementsConfigGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update statement configurations
         * @param {StatementEditableConfig} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsConfigPost(request: StatementEditableConfig, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).statementsConfigPost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download statements
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsDownloadGet(token: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).statementsDownloadGet(token, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate a download token for exported statements
         * @param {StatementGetStatementsRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsDownloadTokenPost(request: StatementGetStatementsRequest, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).statementsDownloadTokenPost(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of statements
         * @param {number} [beginTime] 
         * @param {number} [endTime] 
         * @param {string} [fields] 
         * @param {Array<string>} [schemas] 
         * @param {Array<string>} [stmtTypes] 
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsListGet(beginTime?: number, endTime?: number, fields?: string, schemas?: Array<string>, stmtTypes?: Array<string>, text?: string, options?: any): AxiosPromise<Array<StatementModel>> {
            return DefaultApiFp(configuration).statementsListGet(beginTime, endTime, fields, schemas, stmtTypes, text, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get details of a statement in an execution plan
         * @param {number} [beginTime] 
         * @param {string} [digest] 
         * @param {number} [endTime] 
         * @param {Array<string>} [plans] 
         * @param {string} [schemaName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsPlanDetailGet(beginTime?: number, digest?: string, endTime?: number, plans?: Array<string>, schemaName?: string, options?: any): AxiosPromise<StatementModel> {
            return DefaultApiFp(configuration).statementsPlanDetailGet(beginTime, digest, endTime, plans, schemaName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get execution plans of a statement
         * @param {number} [beginTime] 
         * @param {string} [digest] 
         * @param {number} [endTime] 
         * @param {string} [schemaName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsPlansGet(beginTime?: number, digest?: string, endTime?: number, schemaName?: string, options?: any): AxiosPromise<Array<StatementModel>> {
            return DefaultApiFp(configuration).statementsPlansGet(beginTime, digest, endTime, schemaName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all statement types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsStmtTypesGet(options?: any): AxiosPromise<Array<string>> {
            return DefaultApiFp(configuration).statementsStmtTypesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Query statements table columns
         * @summary Query table columns
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsTableColumnsGet(options?: any): AxiosPromise<Array<string>> {
            return DefaultApiFp(configuration).statementsTableColumnsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get available statement time ranges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statementsTimeRangesGet(options?: any): AxiosPromise<Array<StatementTimeRange>> {
            return DefaultApiFp(configuration).statementsTimeRangesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Hide a TiDB instance
         * @param {string} address ip:port
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        topologyTidbAddressDelete(address: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).topologyTidbAddressDelete(address, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get log in information, like supported authenticate types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetLoginInfo(options?: any): AxiosPromise<UserGetLoginInfoResponse> {
            return DefaultApiFp(configuration).userGetLoginInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get sign out info
         * @param {string} [redirectUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetSignOutInfo(redirectUrl?: string, options?: any): AxiosPromise<UserSignOutInfo> {
            return DefaultApiFp(configuration).userGetSignOutInfo(redirectUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Log in
         * @param {UserAuthenticateForm} message Credentials
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userLogin(message: UserAuthenticateForm, options?: any): AxiosPromise<UserTokenResponse> {
            return DefaultApiFp(configuration).userLogin(message, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an impersonation
         * @param {SsoCreateImpersonationRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSSOCreateImpersonation(request: SsoCreateImpersonationRequest, options?: any): AxiosPromise<SsoSSOImpersonationModel> {
            return DefaultApiFp(configuration).userSSOCreateImpersonation(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get SSO Auth URL
         * @param {string} [codeVerifier] 
         * @param {string} [redirectUrl] 
         * @param {string} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSSOGetAuthURL(codeVerifier?: string, redirectUrl?: string, state?: string, options?: any): AxiosPromise<string> {
            return DefaultApiFp(configuration).userSSOGetAuthURL(codeVerifier, redirectUrl, state, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get SSO config
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSSOGetConfig(options?: any): AxiosPromise<ConfigSSOCoreConfig> {
            return DefaultApiFp(configuration).userSSOGetConfig(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all impersonations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSSOListImpersonations(options?: any): AxiosPromise<Array<SsoSSOImpersonationModel>> {
            return DefaultApiFp(configuration).userSSOListImpersonations(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set SSO config
         * @param {SsoSetConfigRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userSSOSetConfig(request: SsoSetConfigRequest, options?: any): AxiosPromise<ConfigSSOCoreConfig> {
            return DefaultApiFp(configuration).userSSOSetConfig(request, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Share current session and generate a sharing code
         * @param {CodeShareRequest} request Request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userShareSession(request: CodeShareRequest, options?: any): AxiosPromise<CodeShareResponse> {
            return DefaultApiFp(configuration).userShareSession(request, options).then((request) => request(axios, basePath));
        },
        /**
         * View the finished profiling result of a task
         * @summary View the result of a task
         * @param {string} token download token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        viewProfilingSingle(token: string, options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).viewProfilingSingle(token, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * Cancel all profling tasks with a given group ID
     * @summary Cancel all tasks with a given group ID
     * @param {string} groupId group ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cancelProfilingGroup(groupId: string, options?: any) {
        return DefaultApiFp(this.configuration).cancelProfilingGroup(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get information of all hosts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public clusterInfoGetHostsInfo(options?: any) {
        return DefaultApiFp(this.configuration).clusterInfoGetHostsInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get cluster statistics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public clusterInfoGetStatistics(options?: any) {
        return DefaultApiFp(this.configuration).clusterInfoGetStatistics(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Edit a configuration
     * @param {ConfigurationEditRequest} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public configurationEdit(request: ConfigurationEditRequest, options?: any) {
        return DefaultApiFp(this.configuration).configurationEdit(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all configurations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public configurationGetAll(options?: any) {
        return DefaultApiFp(this.configuration).configurationGetAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get action token for download or view profile
     * @param {string} q target query string
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public continuousProfilingActionTokenGet(q: string, options?: any) {
        return DefaultApiFp(this.configuration).continuousProfilingActionTokenGet(q, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current scraping components
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public continuousProfilingComponentsGet(options?: any) {
        return DefaultApiFp(this.configuration).continuousProfilingComponentsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Continuous Profiling Config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public continuousProfilingConfigGet(options?: any) {
        return DefaultApiFp(this.configuration).continuousProfilingConfigGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Continuous Profiling Config
     * @param {ProfilingNgMonitoringConfig} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public continuousProfilingConfigPost(request: ProfilingNgMonitoringConfig, options?: any) {
        return DefaultApiFp(this.configuration).continuousProfilingConfigPost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download Group Profile files
     * @param {number} ts timestamp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public continuousProfilingDownloadGet(ts: number, options?: any) {
        return DefaultApiFp(this.configuration).continuousProfilingDownloadGet(ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Estimate Size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public continuousProfilingEstimateSizeGet(options?: any) {
        return DefaultApiFp(this.configuration).continuousProfilingEstimateSizeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Group Profile Detail
     * @param {number} ts timestamp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public continuousProfilingGroupProfileDetailGet(ts: number, options?: any) {
        return DefaultApiFp(this.configuration).continuousProfilingGroupProfileDetailGet(ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Group Profiles
     * @param {number} [beginTime] 
     * @param {number} [endTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public continuousProfilingGroupProfilesGet(beginTime?: number, endTime?: number, options?: any) {
        return DefaultApiFp(this.configuration).continuousProfilingGroupProfilesGet(beginTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary View Single Profile files
     * @param {string} [address] 
     * @param {string} [component] 
     * @param {string} [profileType] 
     * @param {number} [ts] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public continuousProfilingSingleProfileViewGet(address?: string, component?: string, profileType?: string, ts?: number, options?: any) {
        return DefaultApiFp(this.configuration).continuousProfilingSingleProfileViewGet(address, component, profileType, ts, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all endpoints
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public debugAPIGetEndpoints(options?: any) {
        return DefaultApiFp(this.configuration).debugAPIGetEndpoints(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Send request remote endpoint and return a token for downloading results
     * @param {EndpointRequestPayload} req request payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public debugAPIRequestEndpoint(req: EndpointRequestPayload, options?: any) {
        return DefaultApiFp(this.configuration).debugAPIRequestEndpoint(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download a finished request result.
     * @param {string} token download token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public debugApiDownloadGet(token: string, options?: any) {
        return DefaultApiFp(this.configuration).debugApiDownloadGet(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete all finished profiling tasks with a given group ID
     * @summary Delete all tasks with a given group ID
     * @param {string} groupId group ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProfilingGroup(groupId: string, options?: any) {
        return DefaultApiFp(this.configuration).deleteProfilingGroup(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate metrics relationship graph.
     * @param {DiagnoseGenerateMetricsRelationRequest} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public diagnoseGenerateMetricsRelationship(request: DiagnoseGenerateMetricsRelationRequest, options?: any) {
        return DefaultApiFp(this.configuration).diagnoseGenerateMetricsRelationship(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary View metrics relationship graph.
     * @param {string} token token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public diagnoseMetricsRelationViewGet(token: string, options?: any) {
        return DefaultApiFp(this.configuration).diagnoseMetricsRelationViewGet(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get sql diagnosis reports history
     * @summary SQL diagnosis reports history
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public diagnoseReportsGet(options?: any) {
        return DefaultApiFp(this.configuration).diagnoseReportsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get sql diagnosis report data
     * @summary SQL diagnosis report data
     * @param {string} id report id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public diagnoseReportsIdDataJsGet(id: string, options?: any) {
        return DefaultApiFp(this.configuration).diagnoseReportsIdDataJsGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get sql diagnosis report HTML
     * @summary SQL diagnosis report
     * @param {string} id report id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public diagnoseReportsIdDetailGet(id: string, options?: any) {
        return DefaultApiFp(this.configuration).diagnoseReportsIdDetailGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get diagnosis report status
     * @summary Diagnosis report status
     * @param {string} id report id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public diagnoseReportsIdStatusGet(id: string, options?: any) {
        return DefaultApiFp(this.configuration).diagnoseReportsIdStatusGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate sql diagnosis report
     * @summary SQL diagnosis report
     * @param {DiagnoseGenerateReportRequest} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public diagnoseReportsPost(request: DiagnoseGenerateReportRequest, options?: any) {
        return DefaultApiFp(this.configuration).diagnoseReportsPost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Download all finished profiling results of a task group
     * @summary Download all results of a task group
     * @param {string} token download token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadProfilingGroup(token: string, options?: any) {
        return DefaultApiFp(this.configuration).downloadProfilingGroup(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Download the finished profiling result of a task
     * @summary Download the result of a task
     * @param {string} token download token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadProfilingSingle(token: string, options?: any) {
        return DefaultApiFp(this.configuration).downloadProfilingSingle(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get token with a given group ID or task ID and action type
     * @summary Get action token for download or view
     * @param {string} [id] group or task ID
     * @param {string} [action] action
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getActionToken(id?: string, action?: string, options?: any) {
        return DefaultApiFp(this.configuration).getActionToken(id, action, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get current alert count from AlertManager
     * @param {string} address ip:port
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAlertManagerCounts(address: string, options?: any) {
        return DefaultApiFp(this.configuration).getAlertManagerCounts(address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get AlertManager instance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAlertManagerTopology(options?: any) {
        return DefaultApiFp(this.configuration).getAlertManagerTopology(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Grafana instance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getGrafanaTopology(options?: any) {
        return DefaultApiFp(this.configuration).getGrafanaTopology(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all PD instances
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getPDTopology(options?: any) {
        return DefaultApiFp(this.configuration).getPDTopology(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all profiling tasks with a given group ID
     * @summary List all tasks with a given group ID
     * @param {string} groupId group ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProfilingGroupDetail(groupId: string, options?: any) {
        return DefaultApiFp(this.configuration).getProfilingGroupDetail(groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all profiling groups
     * @summary List all profiling groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProfilingGroups(options?: any) {
        return DefaultApiFp(this.configuration).getProfilingGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get location labels of all TiKV / TiFlash instances
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStoreLocationTopology(options?: any) {
        return DefaultApiFp(this.configuration).getStoreLocationTopology(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all TiKV / TiFlash instances
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getStoreTopology(options?: any) {
        return DefaultApiFp(this.configuration).getStoreTopology(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all TiDB instances
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTiDBTopology(options?: any) {
        return DefaultApiFp(this.configuration).getTiDBTopology(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get information about this TiDB Dashboard
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public infoGet(options?: any) {
        return DefaultApiFp(this.configuration).infoGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all databases
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public infoListDatabases(options?: any) {
        return DefaultApiFp(this.configuration).infoListDatabases(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List tables by database name
     * @param {string} [databaseName] Database name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public infoListTables(databaseName?: string, options?: any) {
        return DefaultApiFp(this.configuration).infoListTables(databaseName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get information about current session
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public infoWhoami(options?: any) {
        return DefaultApiFp(this.configuration).infoWhoami(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Key Visual Dynamic Config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public keyvisualConfigGet(options?: any) {
        return DefaultApiFp(this.configuration).keyvisualConfigGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Key Visual Dynamic Config
     * @param {ConfigKeyVisualConfig} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public keyvisualConfigPut(request: ConfigKeyVisualConfig, options?: any) {
        return DefaultApiFp(this.configuration).keyvisualConfigPut(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Heatmaps in a given range to visualize TiKV usage
     * @summary Key Visual Heatmaps
     * @param {string} [startkey] The start of the key range
     * @param {string} [endkey] The end of the key range
     * @param {number} [starttime] The start of the time range (Unix)
     * @param {number} [endtime] The end of the time range (Unix)
     * @param {'written_bytes' | 'read_bytes' | 'written_keys' | 'read_keys' | 'integration'} [type] Main types of data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public keyvisualHeatmapsGet(startkey?: string, endkey?: string, starttime?: number, endtime?: number, type?: 'written_bytes' | 'read_bytes' | 'written_keys' | 'read_keys' | 'integration', options?: any) {
        return DefaultApiFp(this.configuration).keyvisualHeatmapsGet(startkey, endkey, starttime, endtime, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate a download token for downloading logs
     * @param {Array<string>} [id] task id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public logsDownloadAcquireTokenGet(id?: Array<string>, options?: any) {
        return DefaultApiFp(this.configuration).logsDownloadAcquireTokenGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download logs
     * @param {string} token download token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public logsDownloadGet(token: string, options?: any) {
        return DefaultApiFp(this.configuration).logsDownloadGet(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create and run a new log search task group
     * @param {LogsearchCreateTaskGroupRequest} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public logsTaskgroupPut(request: LogsearchCreateTaskGroupRequest, options?: any) {
        return DefaultApiFp(this.configuration).logsTaskgroupPut(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all log search task groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public logsTaskgroupsGet(options?: any) {
        return DefaultApiFp(this.configuration).logsTaskgroupsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Cancel running tasks in a log search task group
     * @param {string} id task group id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public logsTaskgroupsIdCancelPost(id: string, options?: any) {
        return DefaultApiFp(this.configuration).logsTaskgroupsIdCancelPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a log search task group
     * @param {string} id task group id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public logsTaskgroupsIdDelete(id: string, options?: any) {
        return DefaultApiFp(this.configuration).logsTaskgroupsIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List tasks in a log search task group
     * @param {string} id Task Group ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public logsTaskgroupsIdGet(id: string, options?: any) {
        return DefaultApiFp(this.configuration).logsTaskgroupsIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Preview a log search task group
     * @param {string} id task group id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public logsTaskgroupsIdPreviewGet(id: string, options?: any) {
        return DefaultApiFp(this.configuration).logsTaskgroupsIdPreviewGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry failed tasks in a log search task group
     * @param {string} id task group id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public logsTaskgroupsIdRetryPost(id: string, options?: any) {
        return DefaultApiFp(this.configuration).logsTaskgroupsIdRetryPost(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get the Prometheus address cluster config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public metricsGetPromAddress(options?: any) {
        return DefaultApiFp(this.configuration).metricsGetPromAddress(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query metrics in the given range
     * @summary Query metrics
     * @param {number} [endTimeSec] 
     * @param {string} [query] 
     * @param {number} [startTimeSec] 
     * @param {number} [stepSec] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public metricsQueryGet(endTimeSec?: number, query?: string, startTimeSec?: number, stepSec?: number, options?: any) {
        return DefaultApiFp(this.configuration).metricsQueryGet(endTimeSec, query, startTimeSec, stepSec, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set or clear the customized Prometheus address
     * @param {MetricsPutCustomPromAddressRequest} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public metricsSetCustomPromAddress(request: MetricsPutCustomPromAddressRequest, options?: any) {
        return DefaultApiFp(this.configuration).metricsSetCustomPromAddress(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Profiling Dynamic Config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public profilingConfigGet(options?: any) {
        return DefaultApiFp(this.configuration).profilingConfigGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set Profiling Dynamic Config
     * @param {ConfigProfilingConfig} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public profilingConfigPut(request: ConfigProfilingConfig, options?: any) {
        return DefaultApiFp(this.configuration).profilingConfigPut(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Run statements
     * @param {QueryeditorRunRequest} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public queryEditorRun(request: QueryeditorRunRequest, options?: any) {
        return DefaultApiFp(this.configuration).queryEditorRun(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get details of a slow query
     * @param {string} [connectId] TODO: Switch back to uint64 when modern browser as well as Swagger handles BigInt well.
     * @param {string} [digest] 
     * @param {number} [timestamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slowQueryDetailGet(connectId?: string, digest?: string, timestamp?: number, options?: any) {
        return DefaultApiFp(this.configuration).slowQueryDetailGet(connectId, digest, timestamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download slow query statements
     * @param {string} token download token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slowQueryDownloadGet(token: string, options?: any) {
        return DefaultApiFp(this.configuration).slowQueryDownloadGet(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate a download token for exported slow query statements
     * @param {SlowqueryGetListRequest} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slowQueryDownloadTokenPost(request: SlowqueryGetListRequest, options?: any) {
        return DefaultApiFp(this.configuration).slowQueryDownloadTokenPost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all slow queries
     * @param {number} [beginTime] 
     * @param {Array<string>} [db] 
     * @param {boolean} [desc] 
     * @param {string} [digest] 
     * @param {number} [endTime] 
     * @param {string} [fields] example: \&quot;Query,Digest\&quot;
     * @param {number} [limit] 
     * @param {string} [orderBy] 
     * @param {Array<string>} [plans] for showing slow queries in the statement detail page
     * @param {string} [text] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slowQueryListGet(beginTime?: number, db?: Array<string>, desc?: boolean, digest?: string, endTime?: number, fields?: string, limit?: number, orderBy?: string, plans?: Array<string>, text?: string, options?: any) {
        return DefaultApiFp(this.configuration).slowQueryListGet(beginTime, db, desc, digest, endTime, fields, limit, orderBy, plans, text, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query slowquery table columns
     * @summary Query table columns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public slowQueryTableColumnsGet(options?: any) {
        return DefaultApiFp(this.configuration).slowQueryTableColumnsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Start a profiling task group
     * @summary Start profiling
     * @param {ProfilingStartRequest} req profiling request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public startProfiling(req: ProfilingStartRequest, options?: any) {
        return DefaultApiFp(this.configuration).startProfiling(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get statement configurations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public statementsConfigGet(options?: any) {
        return DefaultApiFp(this.configuration).statementsConfigGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update statement configurations
     * @param {StatementEditableConfig} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public statementsConfigPost(request: StatementEditableConfig, options?: any) {
        return DefaultApiFp(this.configuration).statementsConfigPost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download statements
     * @param {string} token download token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public statementsDownloadGet(token: string, options?: any) {
        return DefaultApiFp(this.configuration).statementsDownloadGet(token, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate a download token for exported statements
     * @param {StatementGetStatementsRequest} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public statementsDownloadTokenPost(request: StatementGetStatementsRequest, options?: any) {
        return DefaultApiFp(this.configuration).statementsDownloadTokenPost(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of statements
     * @param {number} [beginTime] 
     * @param {number} [endTime] 
     * @param {string} [fields] 
     * @param {Array<string>} [schemas] 
     * @param {Array<string>} [stmtTypes] 
     * @param {string} [text] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public statementsListGet(beginTime?: number, endTime?: number, fields?: string, schemas?: Array<string>, stmtTypes?: Array<string>, text?: string, options?: any) {
        return DefaultApiFp(this.configuration).statementsListGet(beginTime, endTime, fields, schemas, stmtTypes, text, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get details of a statement in an execution plan
     * @param {number} [beginTime] 
     * @param {string} [digest] 
     * @param {number} [endTime] 
     * @param {Array<string>} [plans] 
     * @param {string} [schemaName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public statementsPlanDetailGet(beginTime?: number, digest?: string, endTime?: number, plans?: Array<string>, schemaName?: string, options?: any) {
        return DefaultApiFp(this.configuration).statementsPlanDetailGet(beginTime, digest, endTime, plans, schemaName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get execution plans of a statement
     * @param {number} [beginTime] 
     * @param {string} [digest] 
     * @param {number} [endTime] 
     * @param {string} [schemaName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public statementsPlansGet(beginTime?: number, digest?: string, endTime?: number, schemaName?: string, options?: any) {
        return DefaultApiFp(this.configuration).statementsPlansGet(beginTime, digest, endTime, schemaName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all statement types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public statementsStmtTypesGet(options?: any) {
        return DefaultApiFp(this.configuration).statementsStmtTypesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query statements table columns
     * @summary Query table columns
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public statementsTableColumnsGet(options?: any) {
        return DefaultApiFp(this.configuration).statementsTableColumnsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get available statement time ranges
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public statementsTimeRangesGet(options?: any) {
        return DefaultApiFp(this.configuration).statementsTimeRangesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Hide a TiDB instance
     * @param {string} address ip:port
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public topologyTidbAddressDelete(address: string, options?: any) {
        return DefaultApiFp(this.configuration).topologyTidbAddressDelete(address, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get log in information, like supported authenticate types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userGetLoginInfo(options?: any) {
        return DefaultApiFp(this.configuration).userGetLoginInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get sign out info
     * @param {string} [redirectUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userGetSignOutInfo(redirectUrl?: string, options?: any) {
        return DefaultApiFp(this.configuration).userGetSignOutInfo(redirectUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Log in
     * @param {UserAuthenticateForm} message Credentials
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userLogin(message: UserAuthenticateForm, options?: any) {
        return DefaultApiFp(this.configuration).userLogin(message, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an impersonation
     * @param {SsoCreateImpersonationRequest} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userSSOCreateImpersonation(request: SsoCreateImpersonationRequest, options?: any) {
        return DefaultApiFp(this.configuration).userSSOCreateImpersonation(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get SSO Auth URL
     * @param {string} [codeVerifier] 
     * @param {string} [redirectUrl] 
     * @param {string} [state] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userSSOGetAuthURL(codeVerifier?: string, redirectUrl?: string, state?: string, options?: any) {
        return DefaultApiFp(this.configuration).userSSOGetAuthURL(codeVerifier, redirectUrl, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get SSO config
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userSSOGetConfig(options?: any) {
        return DefaultApiFp(this.configuration).userSSOGetConfig(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all impersonations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userSSOListImpersonations(options?: any) {
        return DefaultApiFp(this.configuration).userSSOListImpersonations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set SSO config
     * @param {SsoSetConfigRequest} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userSSOSetConfig(request: SsoSetConfigRequest, options?: any) {
        return DefaultApiFp(this.configuration).userSSOSetConfig(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Share current session and generate a sharing code
     * @param {CodeShareRequest} request Request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public userShareSession(request: CodeShareRequest, options?: any) {
        return DefaultApiFp(this.configuration).userShareSession(request, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * View the finished profiling result of a task
     * @summary View the result of a task
     * @param {string} token download token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public viewProfilingSingle(token: string, options?: any) {
        return DefaultApiFp(this.configuration).viewProfilingSingle(token, options).then((request) => request(this.axios, this.basePath));
    }

}


